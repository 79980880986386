import React from "react";

import {
	Box,
	Typography,
} from "@mui/material";

import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";

import { Trans } from "@lingui/macro";

export class IdentificationError extends React.Component<{}, {}>
{
	render()
	{
		return (<Box
			sx={{
				marginTop: 8,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
			}}
		>
			<SentimentVeryDissatisfiedIcon color="error" sx={{fontSize: 120}} />
			<Typography mt={2} mb={5} variant="h3">
				<Trans id="errors.identification-error-1.message">
					Errore irreversibile
				</Trans>
			</Typography>
			<Typography mt={2} variant="body1">
				<Trans id="errors.identification-error-2.message">
					Si è verificato un errore nel processo di identificazione
				</Trans>
			</Typography>
			<Typography mb={5} variant="body1">
				<Trans id="errors.identification-error-3.message">
					Contattare lo studio
				</Trans>
			</Typography>
		</Box>);
	}
}
