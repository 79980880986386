import React from "react";

import {
	Box,
	Button,
	Typography,
} from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { Link } from "react-router-dom";
import { Trans } from "@lingui/macro";

export class CustomerCreateSuccess extends React.Component<{}, {}>
{
	render()
	{
		return (<Box
			sx={{
				marginTop: 8,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
			}}
		>
			<CheckCircleIcon color="success" sx={{fontSize: 80}} />
			<Typography mt={2} mb={2} variant="h5" textAlign={"center"}>
				<Trans id="ccs-message-title">
					La creazione del cliente è avvenuta con successo
				</Trans>
			</Typography>
			<Typography variant="body1" textAlign={"center"}>
				<Trans id="ccs-message">
					Una mail con le istruzioni per terminare l'identificazione verrà inviata all'indirizzo indicato.
				</Trans>
			</Typography>
			<Typography mb={5} variant="body1" textAlign={"center"}>
				<Trans id="ccs-message-notification">
					Verrà inviata una mail al professionista incaricato quando avrà inserito tutte le informazioni
					necessarie.
				</Trans>
			</Typography>
			<Link to="/customers">
				<Button variant="contained" startIcon={<ArrowBackIcon />}>
					<Trans id="ccs-back-to-customers">
						Torna alla lista clienti
					</Trans>
				</Button>
			</Link>
		</Box>);
	}
}
