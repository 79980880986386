import React from "react";
import {
	Link,
	withRouter
} from "react-router-dom";

import {
	AnalysisFilter,
	AnalysisListResponse,
	AnalysisProps,
	AnalysisState
} from "./models";
import { AnalysesService } from "../../services/analyses.service";
import {
	ErrorResponse,
	Loading,
	RestResponse
} from "@sinossi/mates-react-library";
import {
	Box,
	Fab,
	Typography
} from "@mui/material";
import {
	t,
	Trans
} from "@lingui/macro";
import AnalysesList from "../../components/analyses/analyses-list/analyses-list";
import AddIcon from "@mui/icons-material/Add";
import { CustomersService } from "../../services/customers.service";
import { Customer } from "../models";
import AnalysisSearchForm from "../../components/analyses/analysis-search-form/analysis-search-form";
import NavigationBack from "../../components/navigation/navigation-back/navigation-back";
import ErrorPageBox from "../../components/shared/error-page-box/error-page-box";
import EmptyListBox from "../../components/shared/empty-list-box/empty-list-box";

class Analyses extends React.Component<AnalysisProps, AnalysisState>
{
	constructor(props: any)
	{
		super(props);

		this.state = {
			isLoading: true,
			openError: false,
			errorConfig: {
				options: {showErrorTimestamp: true},
				showErrorCode: true
			},
			analysisList: null,
			analysisFilter: {
				customerId: "",
				startDate: null,
				endDate: null
			},
			customer: null,
		};
	}

	componentDidMount()
	{
		CustomersService.getCustomer(this.props.match.params.id, this.getCustomerCallback);
	}

	refreshList = () => {
		AnalysesService.getAnalysesList(this.state.analysisFilter, this.getAnalysisListCallback);
	};

	private getCustomerCallback = (response: RestResponse<Customer, ErrorResponse>) => {

		if(!response.hasError()) {

			this.setState(() => ({
				customer: response.data,
			}));

			let currentFilter = this.state.analysisFilter;
			currentFilter.customerId = this.state.customer!.id;

			this.setState(() => ({
				analysisFilter: currentFilter,
			}));

			AnalysesService.getAnalysesList(this.state.analysisFilter, this.getAnalysisListCallback);

		} else {
			this.setState(() => ({openError: true}));
		}
	};

	private getAnalysisListCallback = (response: RestResponse<AnalysisListResponse, ErrorResponse>) => {

		if(!response.hasError()) {

			this.setState(() => ({
				analysisList: response.data!.list,
				isLoading: false
			}));

		} else {
			this.setState(() => ({openError: true}));
		}
	};

	private handleSearch = (filter: AnalysisFilter) => {

		filter.customerId = this.props.match.params.id;

		this.setState(() => ({
			analysisFilter: filter,
			isLoading: true
		}));

		AnalysesService.getAnalysesList(this.state.analysisFilter, this.getAnalysisListCallback);
	};

	render()
	{
		return (<Box component="div" className="main-container">
			{this.state.isLoading && <Loading show />}
			<NavigationBack backRoute={`/customers`} />
			<Typography variant="h3">
				<Trans id="customer-analyses-list.header">
					Operazioni
				</Trans>
			</Typography>
			<Typography className="analyses-header" variant="h5" sx={{mb: 3}}>
				{this.state.customer?.denomination}
			</Typography>
			{(!this.state.openError && !this.state.isLoading) && <>
				<AnalysisSearchForm
					searchFunction={this.handleSearch}
					searchFormValues={this.state.analysisFilter}
				/>
				{!this.state.isLoading &&
					<AnalysesList list={this.state.analysisList} refreshList={this.refreshList} />}
				<Link to={`/customer/${this.state.customer?.id}/create-operation`}>
					<Fab id={"action-fab"} color="secondary">
						<AddIcon />
					</Fab>
				</Link>
			</>}
			{this.state.openError && <>
				<ErrorPageBox errorText={t({
					id: "a-error",
					message: "Si è verificato un errore nella ricerca delle analisi"
				})} />
			</>}
			{(!this.state.isLoading && this.state.analysisList?.length === 0) && <>
				<EmptyListBox emptyText={t({
					id: "a-empty",
					message: "Non sono presenti analisi per il cliente selezionato"
				})} />
			</>}
		</Box>);
	}
}

export default withRouter(Analyses);
