import React from "react";

import {
	Box,
	Button,
	Grid,
	Typography,
} from "@mui/material";

import { Trans } from "@lingui/macro";
import LoginBox from "../../components/home/login-box/login-box";

import "./home.css";
import {
	HomeProps,
	HomeState
} from "./models";

export class Home extends React.Component<HomeProps, HomeState>
{
	render()
	{
		return (<Box component="div" className={"login-container"}>
			<Grid container alignItems="stretch" sx={{mt: 5}}>
				<Grid item xs={6}>
					<LoginBox keycloak={this.props.keycloak} />
				</Grid>
				<Grid item xs={6} className={"home-cta-container"}>
					<Typography variant="h4">
						{process.env.REACT_APP_PRODUCT_NAME}
					</Typography>
					<Typography variant="body1" sx={{mt: 2}}>
						<Trans id="home.subtitle">
							La soluzione semplice e chiara per gestire la procedura di verifica secondo la normativa
							antiriciclaggio
						</Trans>
					</Typography>
					<Button
						onClick={() => window.open(process.env.REACT_APP_FIND_OUT_MORE || "about:blank", "_blank")}
						color="primary"
						variant="outlined"
						sx={{mt: 2}}>
						<Trans id={"h-discover-more"}>
							Scopri di più
						</Trans>
					</Button>
				</Grid>
			</Grid>
		</Box>);
	}
}
