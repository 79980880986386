import React from "react";
import {MarketProps, MarketState, PurchaseableElementListResponse} from "./models";
import {ErrorResponse, Loading, RestErrorDialog, RestResponse} from "@sinossi/mates-react-library";
import {ErrorService} from "../../services/error.service";
import {Box, Button, Grid, Typography} from "@mui/material";
import {Trans} from "@lingui/macro";
import {withRouter} from "react-router-dom";
import {
    Office, PurcheaseableElementType
} from "../models";
import {PurcheaseableElementService} from "../../services/purcheaseable-element.service";
import PurcheasebleElementList from "../../components/purcheaseable-elements/purcheaseable-elements-list/purcheaseable-elements-list";
import {OfficeService} from "../../services/office.service";

class Market extends React.Component<MarketProps,MarketState >{

    constructor(props: any)
    {
        super(props);

        this.state = {
            response: null,
            errorConfig: {
                options: {showErrorTimestamp: true},
                showErrorCode: true
            },
            openError: false,
            isLoading: true,
            purchaseableElementList: null,
            filter: {
                type : PurcheaseableElementType.RECHARGE
            },
            office: null
        };

    }

   componentDidMount()
    {
        PurcheaseableElementService.getPurcheaseableElementList(this.state.filter, this.getElementListCallback);
    }

    refreshList = () => {
        PurcheaseableElementService.getPurcheaseableElementList(this.state.filter, this.getElementListCallback);
    };

    private getElementListCallback = (response: RestResponse<PurchaseableElementListResponse, ErrorResponse>) => {

        this.setState(() => ({response: response}));

        if(!response.hasError()) {

            this.setState(() => ({
                purchaseableElementList: response.data!.list,
            }));

        } else {
            this.setState(() => ({openError: true}));
        }

        OfficeService.getUserOffice(this.getOfficeCallback);

    };

    private getOfficeCallback = (response: RestResponse<Office, ErrorResponse>) => {
        this.setState(() => ({response: response}));

        if(!response.hasError()) {
            this.setState(() => ({
                office: response.data,
                isLoading: false
            }));
        } else {
            this.setState(() => ({openError: true}));
        }
    }

    private customersNumber = () : string => {

        if(this.state.office?.boughtObjects == null)
            return "Non hai clienti a disposizione"
        else
            if(this.state.office.boughtObjects.customers === 1)
                return "Hai a disposizione 1 cliente"
        else
            return ` Hai a disposizione ${this.state.office.boughtObjects.customers.toString()}  clienti`
    }

    closeErrorModal = () => {
        this.setState({openError: false});
    };

    goToOrderHistory = () => {
        this.props.history.push(`/order-history`);
    };

    private sortElements = () : any =>{
        return this.state.purchaseableElementList?.sort((a, b) => (a.payload.customers > b.payload.customers) ? 1 : -1)
    }


    render() {
        return (
            <Box component="div" className="main-container">
                 {this.state.isLoading && <Loading show />}
                <RestErrorDialog
                    open={this.state.openError}
                    response={this.state.response}
                    ok={this.closeErrorModal}
                    errorParser={ErrorService.parseErrorCode}
                    {...this.state.errorConfig} />

                <Typography variant="h3">
                    <Trans id="customers.header">Gestione Acquisti</Trans>
                </Typography>

                <Grid container justifyContent="flex-start">
                    <Typography variant="subtitle1" marginBottom="25px">
                        {this.customersNumber()}
                    </Typography>
                </Grid>
                <Grid container justifyContent="flex-start">
                    <Button variant="contained" onClick={this.goToOrderHistory}>Storico ordini</Button>
                </Grid>
                <Grid container justifyContent="flex-start" marginTop="25px">
                    <Typography variant="h6">
                        Acquista
                    </Typography>
                </Grid>

                {!this.state.isLoading && <PurcheasebleElementList
                    list={this.sortElements()}
                    refreshList={this.refreshList} />}


            </Box> );
    }

}

export default withRouter(Market);