import React from "react";

import {
	Box,
	Button,
	Typography,
} from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import {
	Link,
	withRouter
} from "react-router-dom";
import { Trans } from "@lingui/macro";
import {
	OperationCreateSuccessProps,
	OperationCreateSuccessState
} from "./models";

class OperationCreateSuccess extends React.Component<OperationCreateSuccessProps, OperationCreateSuccessState>
{
	constructor(props: any)
	{
		super(props);

		this.state = {
			customerId: "",
		};
	}

	componentDidMount()
	{
		this.setState(() => ({
			customerId: this.props.match.params.customerId,
		}));
	};

	render()
	{
		return (<Box
			sx={{
				marginTop: 8,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
			}}
		>
			<CheckCircleIcon color="success" sx={{fontSize: 80}} />
			<Typography mt={2} mb={2} variant="h5" textAlign={"center"}>
				<Trans id="success-operation.message-title">
					La creazione dell'operazione ha avuto successo
				</Trans>
			</Typography>
			<Link to={`/analyses/${this.state.customerId}`}>
				<Button variant="contained" startIcon={<ArrowBackIcon />}>
					<Trans id="buttons.back-to-operations" comment="Back text on buttons">
						Torna alla lista operazioni
					</Trans>
				</Button>
			</Link>
		</Box>);
	}
}

export default withRouter(OperationCreateSuccess);
