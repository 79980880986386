import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";

import {
	createTheme,
	ThemeProvider,
} from "@mui/material/styles";

import itLocale from "date-fns/locale/it";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterLuxon from "@mui/lab/AdapterLuxon";

import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";

import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { messages as messagesEn } from "./locales/en/messages";
import { messages as messagesIt } from "./locales/it/messages";

import "index.scss";
import App from "./App";
import { NetworkParams } from "@sinossi/mates-react-library";

const theme = createTheme({
	palette: {
		mode: "light",
		primary: {
			main: process.env.REACT_APP_MAIN_COLOR || "#F5B32B",
		},
		secondary: {
			main: process.env.REACT_APP_SECONDARY_COLOR || "#878787",
		}
	},
	typography: {
		fontFamily: '"Roboto Condensed", "Arial", sans-serif',
	}
}, itLocale);

i18n.load({
	"IT": messagesIt,
	"EN": messagesEn,
});
i18n.activate(window.localStorage.getItem("selected_language") || "IT");

NetworkParams.DEFAULT_CONFIG.storageTokenName = "aml_auth_token";

ReactDOM.render(<React.StrictMode>
	<I18nProvider i18n={i18n}>
		<ThemeProvider theme={theme}>
			<BrowserRouter>
				<SnackbarProvider
					maxSnack={5}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center",
					}}
					hideIconVariant={false}>
					<LocalizationProvider dateAdapter={AdapterLuxon} locale="it">
						<App />
					</LocalizationProvider>
				</SnackbarProvider>
			</BrowserRouter>
		</ThemeProvider>
	</I18nProvider>
</React.StrictMode>, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
