import React from "react";

import {
	ErrorPageBoxProps,
	ErrorPageBoxState
} from "./models";
import {
	Box,
	Typography
} from "@mui/material";
import { DangerousOutlined } from "@mui/icons-material";

export default class ErrorPageBox extends React.Component<ErrorPageBoxProps, ErrorPageBoxState>
{

	render()
	{
		return (<Box component="div" sx={{
			marginTop: 8,
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
		}}
		>
			<DangerousOutlined color={"error"} sx={{fontSize: 80}} />
			<Typography mt={2} mb={2} variant="h5" textAlign={"center"} color={"red"}>
				{this.props.errorText}
			</Typography>
		</Box>);
	}

}