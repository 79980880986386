import { i18n } from "@lingui/core"
import { TitleAndMessage } from "@sinossi/mates-react-library"

export class ErrorService
{
	private static errors = (code: number): string => {
		let errorList: Record<number, string> = {
			0: /*i18n*/i18n._("errors.cannot_contact_server"), // Server irraggiungibile
			0x1000: /*i18n*/i18n._("errors.generic_message"), // GENERIC_ERROR
			0x1010: /*i18n*/i18n._("errors.state_machine"), // STATE_MACHINE_ERROR
			0x1020: /*i18n*/i18n._("errors.validation_failed"), // VALIDATION_FAILED
			0x1030: /*i18n*/i18n._("errors.generic_message"), // NETWORK_ERROR
		}

		return errorList[code]
	}

	public static parseErrorCode(code: number): TitleAndMessage
	{
		return {
			title: /*i18n*/i18n._("errors.modal_title"),
			message: ErrorService.errors(code),
		}
	}
}
