import React from "react";
import {UploadDocumentWorkerModalProps, UploadDocumentWorkerModalState} from "./models";

import {
	Button,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Fab,
	Grid,
	Stack,
	TextField,
	Typography
} from "@mui/material";
import {t, Trans} from "@lingui/macro";
import UploadIcon from "@mui/icons-material/Upload";
import CancelIcon from "@mui/icons-material/Cancel";
import DatePicker from "@mui/lab/DatePicker";
import {withSnackbar, WithSnackbarProps} from "notistack";

class UploadDocumentWorkerModal extends React.Component<UploadDocumentWorkerModalProps & WithSnackbarProps, UploadDocumentWorkerModalState>
{

	private handleRevert = () => {

		this.props.closeModal(null);
	};

	constructor(props: any)
	{
		super(props);

		this.state = {
			filename: null,
			file: null,
			expiryDate: null,
			issueDate: null,
			issuer: null,
			number: null,
			type: null
		};
	}

	private parseFile = (event: any) => {

		if(event.target.files[0].size > 15000000) {
			this.uploadFileError()
			return
		}
		this.setState(() => ({
			file: event.target.files[0],
			filename: event.target.files[0].name
		}));
	};

	private uploadFileError = () => {

		this.props.enqueueSnackbar(t({
			id: "udwm-error-upload-file",
			message: "Il file caricato è troppo grande"
		}), {
			variant: "error",
		});

	}

	private removeFile = () => {
		this.setState(() => ({file: null , filename:null}));
	};

	private handleClose = () => {

		let document = {
			documentType: {
				type: "OTHER",
				name: this.state.type
			},
			file: this.state.file,
			expiryDate: this.state.expiryDate,
			number: this.state.number,
			issueDate: this.state.issueDate,
			issuer: this.state.issuer
		};

		this.props.closeModal(document);
	};

	private validateUpload = (): boolean => {

		if (this.state.type === null || this.state.type === "") {
			return false;
		}

		if (this.state.file === null || this.state.file === {}) {
			return false;
		}

		if(this.state.file.size > 15000000){
			return false;
		}


		return true;
	};

	render()
	{
		return (<>
			<DialogTitle color={"primary"}>
				<Trans id={"udwm-title"}> Carica documento </Trans>
			</DialogTitle>
			<DialogContent>
				<DialogContentText>
					<Trans id={"udwm-subtitle"}>
						Inserisci i dati del documento
					</Trans>
				</DialogContentText>
				<Grid container>
					<Grid item xs={12}>
						<TextField
							required={true}
							label={t({
								id: "udwm-type",
								message: "Tipo documento"
							})}
							variant="outlined"
							onChange={(e) => {
								this.setState(() => ({type: e.target.value}));
							}}
							fullWidth />
					</Grid>
					<Grid item xs={12} sx={{mt: 2}}>
						<TextField
							label={t({
								id: "udwm-number",
								message: "Numero"
							})}
							variant="outlined"
							onChange={(e) => {
								this.setState(() => ({number: e.target.value}));
							}}
							fullWidth />
					</Grid>
					<Grid item xs={12} sx={{mt: 2}}>
						<TextField
							label={t({
								id: "udwm-issuer",
								message: "Ente rilasciante"
							})}
							variant="outlined"
							onChange={(e) => {
								this.setState(() => ({issuer: e.target.value}));
							}}
							fullWidth />
					</Grid>
					<Grid item
						xs={6}
						sx={{
							mt: 2,
							pr: 1
						}}>
						<DatePicker
							value={this.state.issueDate}
							onChange={(e) => {
								this.setState(() => ({issueDate: e}));
							}}
							label={t({
								id: "udwm-issue-date",
								message: "Data emissione"
							})}
							renderInput={(params) => <TextField
								fullWidth
								variant="outlined"
								id="udwm-issue-date"
								name="udwm-issue-date"
								{...params} />}
						/>
					</Grid>
					<Grid item
						xs={6}
						sx={{
							mt: 2,
							pl: 1
						}}>
						<DatePicker
							value={this.state.expiryDate}
							onChange={(e) => {
								this.setState(() => ({expiryDate: e}));
							}}
							label={t({
								id: "udwm-expiry-date",
								message: "Data scadenza"
							})}
							renderInput={(params) => <TextField
								fullWidth
								variant="outlined"
								id="udwm-expiry-date"
								name="udwm-expiry-date"
								{...params} />}
						/>
					</Grid>
				</Grid>
				{<Stack direction={"row"} alignItems={"baseline"} sx={{mt: 3}}>
					<label>
						<input
							style={{display: "none"}}
							name="upload-file"
							type="file"
							onChange={this.parseFile} />
						<Fab
							color="secondary"
							size="small"
							component="span"
							aria-label="add"
							variant="extended"
							disabled={this.state.filename != null}>
							<UploadIcon /> <Trans id={"udwm-upload-file"}> Carica File </Trans>
						</Fab>
					</label>
					{this.state.filename != null && <Stack direction={"row"} alignItems={"baseline"}>
						<Typography variant={"body1"}
							sx={{ml: 3}}> {this.state.filename} </Typography>
						<CancelIcon color="error"
							sx={{ml: 2}}
							onClick={this.removeFile}
							cursor={"pointer"} />
					</Stack>}
				</Stack>}
				<label style={{marginTop:"5px", display:"inline-block"}}>
					<Typography variant={"body2"} >
						<Trans id="udwm-upload-size">
							Massimo 15MB
						</Trans>
						</Typography>
				</label>
				<DialogActions>
					<Button onClick={this.handleRevert}> <Trans id="udwm-close"> Chiudi</Trans> </Button>
					{<Button
						onClick={this.handleClose}
						disabled={!this.validateUpload()}
						variant="contained"
						color={"primary"}>
						<Trans id="udwm-upload"> Carica </Trans> </Button>}
				</DialogActions>
			</DialogContent>
		</>);
	}
}

export default withSnackbar(UploadDocumentWorkerModal);