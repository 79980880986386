import React from "react";
import { UseFormReturn } from "react-hook-form";
import {
    ChangeUserModalProps,
    ChangeUserModalState
} from "./models";
import * as Yup from "yup";
import {
    t,
    Trans
} from "@lingui/macro";
import {
    MatesSelect,
    withUseFormHook
} from "@sinossi/mates-react-library";
import { MainConstants } from "../../../pages/models";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";

const ChangeUserModalSchema = Yup.object().shape({
    role: Yup.string().required(t({
        id: "change.user.modal-role-required-msg",
        message: "Il ruolo è obbligatorio"
    }))
});

class ChangeUserModal extends React.Component<ChangeUserModalProps & UseFormReturn, ChangeUserModalState>{


    private submitForm = (values: any) => {
        this.props.handleSubmitCallback(this.props.id, values.role)
    }

    render() {

        let currentLanguage = window.localStorage.getItem("selected_language") || "IT";

        return(
            <>
                <Dialog open={this.props.isOpen}>
                    <DialogTitle color={"primary"}>
                        <Trans id={"change.user.modal-title"}>
                            Modifica ruolo utente
                        </Trans>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Trans id={"change.user.modal-text"}>
                                Attraverso questa modal è possibile modificare il ruolo dell'utente selezionato.
                                Clicca su salva per confermare la scelta.
                            </Trans>
                        </DialogContentText>
                        <br />


                        {<form onSubmit={this.props.handleSubmit(this.submitForm)}>
                            <MatesSelect
                                variant={"outlined"}
                                name="role"
                                label={t({
                                    id: "change.user.modal-role",
                                    message: "Ruolo"
                                })}
                                control={this.props.control}
                                formState={this.props.formState}
                                trigger={this.props.trigger}>

                                {MainConstants.USER_ROLES.map(
                                    (item) => (<option value={item.key} key={item.key}>{currentLanguage === "IT"? item.label : item.labelEn}</option>))}
                            </MatesSelect>
                            <DialogActions>
                                <Button onClick={this.props.handleClose}> <Trans id="change.user.modal-close"> Chiudi</Trans> </Button>
                                {<Button
                                    variant="contained"
                                    color={"primary"}
                                    disabled={this.props.formState.isSubmitting}
                                    type="submit">
                                    <Trans id="change.user.modal-create"> Salva </Trans>
                                </Button>}
                            </DialogActions>
                        </form>}
                    </DialogContent>
                </Dialog>
            </>
        )
    }

}

export default withUseFormHook<{}>(ChangeUserModal, ChangeUserModalSchema, {});

/*

 */
