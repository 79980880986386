import {
	RequestedDocumentProps,
	RequestedDocumentState
} from "./models";
import React from "react";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Fab,
	Grid,
	Stack,
	TextField,
	Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UploadIcon from "@mui/icons-material/Upload";
import CancelIcon from "@mui/icons-material/Cancel";
import { DocumentType } from "../../models";
import DatePicker from "@mui/lab/DatePicker";
import {
	t,
	Trans
} from "@lingui/macro";
import {withSnackbar, WithSnackbarProps} from "notistack";

class RequestedDocument extends React.Component<RequestedDocumentProps & WithSnackbarProps, RequestedDocumentState>
{

	constructor(props: any)
	{
		super(props);

		this.state = {
			issuer: null,
			expiryDate: null,
			issueDate: null,
			number: null,
			documentFile: {},
			issueDateError: false,
			expiryDateError: false
		};
	}

	private parseFile = (event: any) => {

		let document = {
			file: event.target.files[0],
			name: this.props.document!.name,
			id: this.props.myKey,
			documentType: this.props.document!.type,
			expiryDate: this.state.expiryDate,
			number: this.state.number,
			issueDate: this.state.issueDate,
			issuer: this.state.issuer,
			person: this.props.person !== null ? this.props.person : null
		};

		if(document.file.size > 15000000) {
			this.uploadFileError()
			return
		}
		this.setState(() => ({documentFile: document.file}));

		this.props.fileUploadFunction(document);
	};

	private removeFile = () => {

		this.setState(() => ({documentFile: {}}));

		this.props.removeDocument(this.props.myKey);
	};

	private isIssueDateInvalid = () => {
		return false;
	};

	private checkIssueDate = (e: any) => {
		let data = new Date();
		let minYear = data.getFullYear() - 10;

		if(e.c.year < minYear){
			this.setState(() => ({issueDateError: true}));
		}
		else {
			this.setState(() => ({issueDate: e}));
		}
	};

	private checkExpiryDate = (e: any) => {
		let data = new Date();
		let minYear = data.getFullYear() - 10;
		let expiryDate = new Date(e.c.year, e.c.month, e.c.day);
		let iussueDate = new Date(this.state.issueDate.year, this.state.issueDate.month, this.state.issueDate.day);

		if(e.c.year < minYear || expiryDate.getTime() < iussueDate.getTime()){
			this.setState(() => ({expiryDateError: true}));
			console.log("error");
			console.log(this.state.expiryDate);
		}
		else {
			this.setState(() => ({expiryDate: e}));
			console.log("success");
			console.log(this.state.expiryDate);
		}
	};


	private uploadFileError = () => {

		this.props.enqueueSnackbar(t({
			id: "rd-error-upload-file",
			message: "Il file caricato è troppo grande"
		}), {
			variant: "error",
		});

	}

	render()
	{
		return (<>
			<Accordion id={"we" + Math.random()} sx={{mt: 1}}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header">
					{this.props.document!.type === DocumentType.ID_CARD && <Typography>
						<Trans id={"rd-id-card"}>
							Carta di identità
						</Trans>
					</Typography>}
					{this.props.document!.type === DocumentType.OTHER_IDENTIFICATION_METHOD && <Typography>
						<Trans id={"other"}>
							Altro documento di identità - Passaporto, Patente, Porto d'Armi etc..
						</Trans>
					</Typography>}
					{this.props.document!.type === DocumentType.BANK_ACCOUNT_STATEMENT && <Typography>
						<Trans id={"rd-bank-statement"}>
							Estratto conto
						</Trans>
					</Typography>}
					{this.props.document!.type === DocumentType.CCIA_REPORT && <Typography>
						<Trans id={"rd-ccia-report"}>
							Visura Camerale
						</Trans>
					</Typography>}
					{this.props.document!.type === DocumentType.OWNER_APPOINTMENT_VERBAL && <Typography>
						<Trans id={"rd-owner-appointment-verbal"}>
							Verbale di nomina degli amministratori
						</Trans>
					</Typography>}
					{this.props.document!.type === DocumentType.COMPANY_CONSTITUTION_ACT && <Typography>
						<Trans id={"rd-constitution-act"}>
							Atto costitutivo
						</Trans>
					</Typography>}
					{this.props.document!.type === DocumentType.LAST_BALANCE && <Typography>
						<Trans id={"rd-last-balance"}>
							Ultimo Bilancio
						</Trans>
					</Typography>}
					{this.props.document!.type === DocumentType.OTHER && <Typography>
						{this.props.document!.name}
					</Typography>}
					{(this.props.isPerson && this.props.person !== undefined) && <Typography sx={{ml: 1}}>- &nbsp;
						<b> {this.props.person.name} {this.props.person.surname} </b> - {this.props.person.fiscalCode}
					</Typography>}
				</AccordionSummary>
				<AccordionDetails>
					<Grid container>
						<Grid item xs={12}>
							<TextField
								label={t({
									id: "rd-number",
									message: "Numero"
								})}
								variant="outlined"
								onChange={(e) => {
									this.setState(() => ({number: e.target.value}));
								}}
								fullWidth />
						</Grid>
						<Grid item xs={12} sx={{mt: 2}}>
							<TextField
								label={t({
									id: "rd-issuer",
									message: "Ente rilasciante"
								})}
								variant="outlined"
								onChange={(e) => {
									this.setState(() => ({issuer: e.target.value}));
								}}
								fullWidth />
						</Grid>
						<Grid item
							xs={6}
							sx={{
								mt: 2,
								pr: 1
							}}>
							<DatePicker
								value={this.state.issueDate}
								onChange={(e) => {
									this.checkIssueDate(e);
								}}
								label={t({
									id: "rd-issue-date",
									message: "Data emissione"
								})}
								renderInput={(params) => <TextField
									fullWidth
									variant="outlined"
									id="rd-issue-date"
									name="rd-issue-date"
									error={this.state.issueDateError}
									{...params} />}
							/>
						</Grid>
						{(this.props.document!.type === DocumentType.ID_CARD || this.props.document!.type === DocumentType.OTHER_IDENTIFICATION_METHOD)&& <Grid item
							xs={6}
							sx={{
								mt: 2,
								pl: 1
							}}>
							<DatePicker
								value={this.state.expiryDate}
								onChange={(e) => {
									this.checkExpiryDate(e);
								}}
								label={t({
									id: "rd-expiry-date",
									message: "Data scadenza"
								})}
								renderInput={(params) => <TextField
									fullWidth
									variant="outlined"
									id="rd-expiry-date"
									name="rd-expiry-date"
									error={this.state.expiryDateError}
									{...params} />}
							/>
						</Grid>}
						<Grid item xs={12} sx={{mt: 2}}>
							<Stack direction={"row"} alignItems={"baseline"}>
								<label htmlFor={this.props.myKey}>
									<input
										style={{display: "none"}}
										id={this.props.myKey}
										name="upload-file"
										type="file"
										onChange={this.parseFile}
									/>
									<Fab
										color="secondary"
										size="small"
										component="span"
										aria-label="add"
										variant="extended"
										disabled={this.state.documentFile.name != null}>
										<UploadIcon /> <Trans id={"rd-file-upload"}>Carica documenti</Trans>
									</Fab>
								</label>
								{this.state.documentFile.name != null &&
									<Stack direction={"row"} alignItems={"baseline"}>
										<Typography variant={"body1"}
											sx={{ml: 3}}> {this.state.documentFile.name} </Typography>
										<CancelIcon color="error"
											sx={{ml: 2}}
											onClick={this.removeFile}
											cursor={"pointer"} />
									</Stack>}
							</Stack>
							<label style={{marginTop:"5px", display:"inline-block"}}>
								<Typography variant={"body2"} >
									<Trans id="rd-upload-size">
										Massimo 15MB
									</Trans>
								</Typography>
							</label>
						</Grid>
					</Grid>
				</AccordionDetails>
			</Accordion>
		</>);
	}
}

export default withSnackbar(RequestedDocument);