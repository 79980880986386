import React from "react";

import {
	EmptyListProps,
	EmptyListState,
} from "./models";
import {
	Box,
	Typography
} from "@mui/material";
import { ListOutlined } from "@mui/icons-material";

export default class EmptyListBox extends React.Component<EmptyListProps, EmptyListState>
{

	render()
	{
		return (<Box component="div" sx={{
			marginTop: 8,
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
		}}
		>
			<ListOutlined sx={{
				fontSize: 80,
				color: "gray"
			}} />
			<Typography mt={2} mb={2} variant="h5" textAlign={"center"} color={"gray"}>
				{this.props.emptyText}
			</Typography>
		</Box>);
	}

}