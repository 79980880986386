import { Customer } from "../pages/models";
import {
	NetworkParams,
	NetworkService,
	RestService
} from "@sinossi/mates-react-library";
import { CustomersFilter } from "../pages/customers/models";

export class CustomersService
{

	static getCustomersList = (request: CustomersFilter, callback: any) => {

		RestService.post<Customer>("/api/customers/list", request)
			.then(callback);
	};

	static onBoard = (request: any, callback: any) => {

		RestService.post<Customer>("/api/customers/start-onboarding", request)
			.then(callback);
	};

	static deleteCustomer = (id: any, callback: any) => {

		RestService.delete<Customer>(`/api/customers/${id}`)
			.then(callback);
	};

	static repeatVerifieCustomer = (id: any, callback: any) => {

		NetworkService.get(`/api/customers/${id}/repeat-verifie`)
			.then(callback);
	}

	static confirmInsertedData = (id: any, authCode: string, callback: any) => {

		RestService.put<null>(`/api/customers/external/${id}/confirm-inserted-data?authCode=${authCode}`, null)
			.then(callback);
	};

	static getCustomer = (id: any, callback: any) => {

		RestService.get<Customer>(`/api/customers/${id}`)
			.then(callback);
	};

	static getCustomerExternal = (id: any, authCode: any, callback: any) => {

		localStorage.removeItem(NetworkParams.DEFAULT_CONFIG.storageTokenName);

		RestService.get<Customer>(`/api/customers/external/${id}?authString=${authCode}`)
			.then(callback);
	};

	static verifyCustomer = (id: any, result: boolean, callback: any) => {

		RestService.put<Customer>(`/api/customers/${id}/verify/${result}`, null)
			.then(callback);
	};

	static uploadDocument = (file: any, requestData: any, successCallback: any, errorCallback: any) => {

		localStorage.removeItem(NetworkParams.DEFAULT_CONFIG.storageTokenName);

		let formData = new FormData();

		formData.append("file", file);
		requestData.file = null;
		formData.append("documentInfo", JSON.stringify(requestData));

		fetch("/api/documents/upload", {
			method: "PUT",
			body: formData
		})
			.then(response => {

				if(!response.ok) {
					errorCallback(response);
					return;
				}

				successCallback();
			})
			.catch(errorResponse => {
				errorCallback(errorResponse);
			});
	};

	static uploadDocumentExternal = (authCode: string, customerId: string, file: any, requestData: any,
		successCallback: any, errorCallback: any
	) => {

		localStorage.removeItem(NetworkParams.DEFAULT_CONFIG.storageTokenName);

		let formData = new FormData();

		formData.append("file", file);
		formData.append("documentInfo", JSON.stringify(requestData));
		formData.append("authCode", authCode);
		formData.append("customerId", customerId);

		fetch("/api/documents/external/upload", {
			method: "PUT",
			body: formData
		})
			.then(response => {

				if(!response.ok) {
					errorCallback(response);
					return;
				}

				successCallback();
			})
			.catch(errorResponse => {
				errorCallback(errorResponse);
			});
	};

	static checkCustomerExists = (request: Customer, callback: any) => {

		NetworkService.post("/api/customers/exists", JSON.stringify(request))
			.then(callback)
			.catch((err) => console.log(err));
	};

	static resendOnboardingMail = (id: any, callback: any) => {

    RestService.put<Customer>(`/api/customers/${id}/resend-onboarding-email`, null)
      .then(callback);
  };

  static updateContactData = (id: any, request: any, callback: any) => {
    RestService.put<Customer>(`/api/customers/${id}/update-contact-data`, request)
        .then(callback);
  }

  static updateCustomer = (id: any, request: any, callback: any) => {
    RestService.post<Customer>(`/api/customers/update`, request)
        .then(callback);
  }

  static updateWorker = (id: any, workerId: any, callback: any) => {
    RestService.put<Customer>(`/api/customers/${id}/update-worker/${workerId}`, null)
          .then(callback);
  }
}
