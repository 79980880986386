import React from "react";

import {
	Box,
	Button,
	Grid,
	Typography
} from "@mui/material";
import {
	t,
	Trans
} from "@lingui/macro";

import {
	RegistrationFormProps,
	RegistrationFormState
} from "./models";
import { UseFormReturn } from "react-hook-form";
import {
	User,
	UserRole
} from "../../../pages/models";
import {
	ErrorResponse,
	MatesTextField,
	RestResponse,
	withUseFormHook
} from "@sinossi/mates-react-library";
import { UsersService } from "../../../services/users.service";
import * as Yup from "yup";

const RegistrationSchema = Yup.object().shape({

	email: Yup.string().email(t({
		id: "rf-cs-email-valid-msg",
		message: "Inserire un indirizzo e-mail valido"
	})).required(t({
		id: "rf-cs-email-required-msg",
		message: "L'indirizzo e-mail è obbligatorio"
	})),
	name: Yup.string().required(t({
		id: "rf-cs-name-required-msg",
		message: "Il nome è obbligatorio "
	})),

});

class RegistrationForm extends React.Component<RegistrationFormProps & UseFormReturn, RegistrationFormState>
{

	private validateForm = (values: any) => {

	};

	private submitForm = (values: any) => {

		let user: any = {
			creationDate: null,
			deleted: false,
			email: values.email,
			id: "",
			password: values.password,
			lastLogin: null,
			name: values.name,
			office: null,
			role: UserRole.OFFICE_ADMIN,
			surname: values.surname
		};
		UsersService.registerUser(user, this.registerUserCallback);
	};

	private registerUserCallback = (response: RestResponse<User, ErrorResponse>) => {

		if(response.hasError()) {
			console.log("ERROR");
			console.log(response);
		} else {
			this.props.keycloak.login({
				redirectUri: process.env.REACT_APP_BASE_URL + "/customers"
			});
		}

	};

	render()
	{
		return (<Box
			className="loginContainer">
			<Typography variant="h5" sx={{mb: 3}}>
				<Trans id="rf-data-header">
					Dati di registrazione
				</Trans>
			</Typography>
			<form onSubmit={this.props.handleSubmit(this.submitForm)}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<MatesTextField
							variant={"outlined"}
							name="name"
							label={t({
								id: "rf-name",
								message: "Nome"
							})}
							control={this.props.control}
							trigger={this.props.trigger}
							formState={this.props.formState} />
					</Grid>
					<Grid item xs={12}>
						<MatesTextField
							variant={"outlined"}
							name="surname"
							label={t({
								id: "rf-surname",
								message: "Cognome"
							})}
							control={this.props.control}
							trigger={this.props.trigger}
							formState={this.props.formState} />
					</Grid>
					<Grid item xs={12}>
						<MatesTextField
							variant={"outlined"}
							name="email"
							label={t({
								id: "rf-email",
								message: "Indirizzo e-mail"
							})}
							control={this.props.control}
							trigger={this.props.trigger}
							formState={this.props.formState} />
					</Grid>
					<Grid item xs={12}>
						<MatesTextField
							variant={"outlined"}
							type={"password"}
							name="password"
							label={t({
								id: "rf-password",
								message: "Password"
							})}
							control={this.props.control}
							trigger={this.props.trigger}
							formState={this.props.formState} />
					</Grid>
					<Grid item xs={12}>
						<Button
							sx={{mt: 1}}
							variant={"outlined"}
							color="primary"
							type="submit">
							<Trans id={"rf-register-action"}>
								Registrati
							</Trans>
						</Button>
					</Grid>
				</Grid>
			</form>
		</Box>);
	}
}

export default withUseFormHook<{}>(RegistrationForm, RegistrationSchema, {});
