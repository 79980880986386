import {
	Customer,
	Operation,
	RiskValue,
	User
} from "../../../pages/models";

import { ReactNode } from "react";

export interface CustomDocumentToRenderModel
{
	id: string | null,
	node: ReactNode | null
}

export enum AdvTemplateLanguage
{
	IT = "IT", EN = "EN"
}

export const ADV_TEMPLATE_LANGUAGE_LIST: any = [
	{
		key: AdvTemplateLanguage.IT,
		label: "Italiano",
		labelEn: "Italian"
	},
	{
		key: AdvTemplateLanguage.EN,
		label: "Inglese",
		labelEn: "English"
	},
];

export interface RiskRecapState
{
	expectedADV: any,
	expectedADVExpiry: any
	selectedWorker: User | null,
	selectedADV: any,
	selectedADVExpiry: any,
	isLoading: boolean,
	onError: boolean,
	riskValue: RiskValue,
	formLanguage: AdvTemplateLanguage,
	officeWorkers: Array<User> | undefined,
	customDocumentsNumber: number,
	customDocumentsToRender: CustomDocumentToRenderModel []
	customDocumentsList: any[],
	requiredDocumentsList: any[],
	submitted: boolean,
	currentUser: any
}

export interface RiskRecapProps
{
	customer: Customer | null;
	operation: Operation | null;
	back: any;
	onOnboardingStart: any;
	onboardingError: boolean
}

export interface WorkersListResponse
{
	list: Array<User> | undefined,
	size: number
}
