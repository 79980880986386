import React from "react";

import {
	Box,
	IconButton,
	Typography
} from "@mui/material";
import { i18n } from "@lingui/core";

interface FooterState
{
	selected_language: string | null;
}

export class Footer extends React.Component<{}, FooterState>
{
	constructor(props: any)
	{
		super(props);

		this.state = {
			selected_language: "IT"
		};
	}

	componentDidMount()
	{
		this.setState(() => ({selected_language: localStorage.getItem("selected_language") || "IT"}));
	}

	changeLanguage = () => {
		if(this.state.selected_language === "IT") {
			this.setState(() => ({selected_language: "EN"}));
			localStorage.setItem("selected_language", "EN");
		} else {
			this.setState(() => ({selected_language: "IT"}));
			localStorage.setItem("selected_language", "IT");
		}

		i18n.activate(window.localStorage.getItem("selected_language") || "IT");
	};

	render()
	{
		return (<Box
			component="footer"
			sx={{
				py: 2,
				px: 3,
				mt: "auto", // ml: "240px",
				backgroundColor: (theme) => theme.palette.grey[300],
				zIndex: (theme) => theme.zIndex.drawer,
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
			}}>
			<Typography variant="body2">
				&copy; 2021 - {(new Date()).getFullYear()} {process.env.REACT_APP_SOFTWARE_COPYRIGHT_COMPANY} -
				Applicazione realizzata in
				conformità alle linee guida del Consiglio Nazionale Forense (D.L 90/2017)
			</Typography>
			<IconButton aria-label="changeLanguage" component="span" onClick={this.changeLanguage} sx={{ml: 2}}>
				{this.state.selected_language === "EN" && <img
					src="https://flagcdn.com/16x12/it.png"
					srcSet="https://flagcdn.com/32x24/it.png 2x, https://flagcdn.com/48x36/it.png 3x"
					width="16"
					height="12"
					alt="Italy" />}
				{this.state.selected_language === "IT" && <img
					src="https://flagcdn.com/16x12/gb.png"
					srcSet="https://flagcdn.com/32x24/gb.png 2x, https://flagcdn.com/48x36/gb.png 3x"
					width="16"
					height="12"
					alt="United Kingdom" />}
			</IconButton>
		</Box>);
	}
}
