import { AnalysisFilter } from "../pages/analyses/models";
import { Analysis } from "../pages/models";
import { RestService } from "@sinossi/mates-react-library";
import { CreateAnalysisRequest } from "../pages/create-operation/model";

export class AnalysesService
{

	static getAnalysesList = (request: AnalysisFilter, callback: any) => {

		RestService.post<Analysis>("/api/analyses/list", request)
			.then(callback)
			.catch(err => console.log(err));
	};

	static deleteAnalysis = (id: any, callback: any) => {

		RestService.delete<Analysis>(`/api/analyses/${id}`)
			.then(callback);
	};

	static getAnalysis = (id: string, callback: any) => {

		RestService.get<Analysis>(`/api/analyses/${id}`)
			.then(callback);
	};

	static closeAnalysis = (id: string, callback: any) => {

		RestService.put<Analysis>(`/api/analyses/${id}/close`, {})
			.then(callback);
	};

	static saveAnalysis = (request: Analysis, callback: any) => {

		RestService.put<Analysis>("/api/analyses", request)
			.then(callback);
	};

	static saveAnalysisText = (id: string | null, request: { text: string | null }, callback: any) => {

		RestService.put<Analysis>(`/api/analyses/${id}/save-text`, request)
			.then(callback);
	};

	static createAnalysis = (request: CreateAnalysisRequest, callback: any) => {

		RestService.post<Analysis>(`/api/analyses`, request)
			.then(callback);
	};
}
