import {
	RestService
} from "@sinossi/mates-react-library";

export class OfficeSetupService
{
	static sendOfficeSetupRequest = (officeData: any, callback: any) => {

		RestService.post<null>(`/api/offices/activate/`, officeData)
			.then(callback);
	};
}