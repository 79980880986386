import React from "react";

import {
	Box,
	Button,
	Typography,
} from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { Link } from "react-router-dom";
import { Trans } from "@lingui/macro";

export class CustomerVerifySuccess extends React.Component<{}, {}>
{
	render()
	{
		return (<Box
			sx={{
				marginTop: 8,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
			}}
		>
			<CheckCircleIcon color="success" sx={{fontSize: 80}} />
			<Typography mt={2} mb={2} variant="h5" textAlign={"center"}>
				<Trans id="cvs-message-title">
					L'aggiornamento dell'anagrafica ha avuto successo
				</Trans>
			</Typography>
			<Link to="/customers">
				<Button variant="contained" startIcon={<ArrowBackIcon />}>
					<Trans id="cvs-back-to-customers">
						Torna alla lista clienti
					</Trans>
				</Button>
			</Link>
		</Box>);
	}
}
