import React from "react";

import * as Yup from "yup";
import { UseFormReturn } from "react-hook-form";

import {
    CustomerFormProps,
    CustomerFormState
} from "./models";

import {
    Customer,
    MainConstants,
    UserRole
} from "../../../pages/models";

import {
    Button,
    Grid,
    InputAdornment,
    Stack
} from "@mui/material";

import {
    MatesSelect,
    MatesSwitch,
    MatesTextField,
    withUseFormHook,
} from "@sinossi/mates-react-library";

import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import InfoIcon from "@mui/icons-material/Info";

import {
    t,
    Trans
} from "@lingui/macro";

import "./customer-form.css";
import InfoDialog from "../../shared/info-dialog/info-dialog";
import {
    withSnackbar,
    WithSnackbarProps
} from "notistack";
import { CustomersService } from "../../../services/customers.service";

const phoneRegExp = /^\+?\d{2,}$/;

const CustomerSchema = Yup.object().shape({

    fiscalCode: Yup.string().required(t({
        id: "cf-cs-codfiscale-required-msg",
        message: "Il codice fiscale è obbligatorio"
    })),
    email: Yup.string().email(t({
        id: "cf-cs-email-valid-msg",
        message: "Inserire un indirizzo e-mail valido"
    })).required(t({
        id: "cf-cs-email-required-msg",
        message: "L'indirizzo e-mail è obbligatorio"
    })), // denomination: Yup.string().required(t({
    // 	id: "cf-cs-denomination-required-msg",
    // 	message: "La denominazione è obbligatoria"
    // })),
    legalNature: Yup.string().required(t({
        id: "cf-cs-legal-nature-required-msg",
        message: "La natura giuridica è obbligatoria"
    })),
    mainActivity: Yup.string().required(t({
        id: "cf-cs-main-activity-required-msg",
        message: "La prevalente attività è obbligatoria"
    })),
    geoArea: Yup.string().required(t({
        id: "cf-cs-geo-area-required-msg",
        message: "L'area di residenza è obbligatoria"
    })),
    contactPhone: Yup.string().matches(phoneRegExp, t({
        id: "cf-cs-phone-number-valid-msg",
        message: "Inserire un numero di telefono valido"
    })).required(t({
        id: "cf-cs-phone-number-required-msg",
        message: "Il numero di cellulare è obbligatorio"
    })),
    contactName: Yup.string().required(t({
        id: "cf-cs-name-required-msg",
        message: "Il nome di contatto è obbligatorio"
    })),
    contactSurname: Yup.string().required(t({
        id: "cf-cs-surname-required-msg",
        message: "Il cognome di contatto è obbligatorio"
    })),

});

class CustomerForm extends React.Component<CustomerFormProps & UseFormReturn & WithSnackbarProps, CustomerFormState> {
    constructor(props: any) {
        super(props);

        this.state = {
            insertedCustomer: null,
            isLoading: true,
            isMainActivityInfoModalOpen: false,
            isContactDataChange: false,
            user: null,
        };
    }

    private submitForm = (values: any) => {

        let pfOrDiCheck = values.legalNature === "PF" || values.legalNature === "DI" || values.legalNature === "PP";

        let customer: Customer = {
            additionalInfo: {
                collaborative: values.collaborative != null ? values.collaborative : false,
                mainActivity: values.mainActivity,
                ateco2007: null,
                constitutionDate: null,
                birthDate: null,
                address: null,
                businessType: null,
                authCode: null,
                advDuration: null,
                requiredDocuments: null
            },
            deleted: null,
            efestoProcessId: null,
            fiscalCode: values.fiscalCode,
            id: null,
            name: pfOrDiCheck ? values.name : null,
            office: null,
            ownerList: null,
            pec: null,
            status: null,
            surname: pfOrDiCheck ? values.surname : null,
            vatCode: null,
            verificationExpireDate: null,
            verificationType: null,
            email: values.email,
            denomination: !pfOrDiCheck ? values.denomination : null,
            contactName: values.contactName,
            contactSurname: values.contactSurname,
            contactPhone: values.contactPhone,
            legalNature: values.legalNature,
            geoArea: values.geoArea,
            managementCode: values.managementCode,
            worker: null,
            documentList: null
        };

        this.setState(() => ({
            insertedCustomer: customer,
            isLoading: true
        }));

        CustomersService.checkCustomerExists(customer, this.checkCustomerExistsCallback);
    };

    componentDidMount() {

        this.setState(() => ({ isLoading: true }));

        if (this.props.customer !== null) {
            this.props.reset(this.props.customer);
        }

        let currentUserString = window.sessionStorage.getItem("currentUser");
        let currentUser: any = null;

        if (currentUserString != null) {
            currentUser = JSON.parse(currentUserString);
        }

        this.setState(() => ({
            user: currentUser
        }));

        this.setState(() => ({ isLoading: false }));
    }

    private checkCustomerExistsCallback = (response: any) => {

        this.setState(() => ({ isLoading: false }));

        console.log(response);

        if (response.status !== 200) {
            this.props.enqueueSnackbar(t({
                id: "cf-error-customer-exists-error",
                message: "Il cliente inserito esiste già"
            }), {
                variant: "error",
            });
        } else {
            this.props.onCustomerSubmit(this.state.insertedCustomer);
        }
    };

    setMainActivityInfoModalVisibility = () => {
        this.setState(() => ({ isMainActivityInfoModalOpen: !this.state.isMainActivityInfoModalOpen }));
    };

    customIsValid = () => {

        let pfOrDiCheck = this.props.getValues().legalNature === "PF" || this.props.getValues().legalNature === "DI" || this.props.getValues().legalNature === "PP";

        if (pfOrDiCheck) {

            let name = this.props.getValues("name");
            if (name === undefined || name === "") {
                return false;
            }

            let surname = this.props.getValues("surname");
            if (surname === undefined || surname === "") {
                return false;
            }
        }

        if (!pfOrDiCheck) {
            let denomination = this.props.getValues("denomination");
            if (denomination === undefined || denomination === "") {
                return false;
            }
        }

        return true;
    };

    updateDataCallback = (response: any) => {

        if (!response.hasError()) {
            this.setState(() => ({ isContactDataChange: false }));
        } else {
            this.props.enqueueSnackbar(t({
                id: "cf-error-update-contact-data",
                message: "Errore nel salvataggio dei dati"
            }), {
                variant: "error",
            });
        }
    }

    private saveChanges = () => {

        let request = {
            ...this.props.customer,
            denomination: this.props.getValues().denomination,
            contactName: this.props.getValues().contactName,
            contactSurname: this.props.getValues().contactSurname,
            contactPhone: this.props.getValues().contactPhone,
            email: this.props.getValues().email,
            managementCode: this.props.getValues().managementCode,
            legalNature: this.props.getValues().legalNature
        };

        CustomersService.updateCustomer(this.props.customer?.id, request, this.updateDataCallback);
    }

    private checkUserCanEditCustomer = () => {
        return ((this.state.user?.role === UserRole.WORKER && this.props.customer?.worker === this.state.user) || (this.state.user?.role !== UserRole.SECRETARY))
    }

    render() {
        let pfOrDiCheck = this.props.getValues().legalNature === "PF" || this.props.getValues().legalNature === "DI" || this.props.getValues().legalNature === "PP";

        let currentLanguage = window.localStorage.getItem("selected_language") || "IT";

        return (<>
            {this.state.isMainActivityInfoModalOpen && <InfoDialog simpleText={t({
                id: "cf-main-activity-info-text",
                message: "Lorem ipsum dolor sit amet"
            })} title={t({
                id: "cf-main-activity-info-title",
                message: "Prevalente attività"
            })}
                closingButtonAction={this.setMainActivityInfoModalVisibility} />}
            {!this.state.isLoading && <form onSubmit={this.props.handleSubmit(this.submitForm)}>
                <Grid container spacing={2}>
                    {!pfOrDiCheck && <Grid item xs={12}>
                        <MatesTextField
                            variant={"outlined"}
                            name="denomination"
                            label={t({
                                id: "cf-denomination",
                                message: "Denominazione"
                            })}
                            control={this.props.control}
                            trigger={this.props.trigger}
                            formState={this.props.formState}
                            onChange={(e) => {
                                this.setState(() => ({ isContactDataChange: true }));
                                return e
                            }}
                            disabled={false} />
                    </Grid>}
                    {pfOrDiCheck && <>
                        <Grid item xs={6}>
                            <MatesTextField
                                variant={"outlined"}
                                name="name"
                                label={t({
                                    id: "cf-name",
                                    message: "Nome"
                                })}
                                control={this.props.control}
                                trigger={this.props.trigger}
                                formState={this.props.formState}
                                disabled={!this.checkUserCanEditCustomer()} />
                        </Grid>
                        <Grid item xs={6}>
                            <MatesTextField
                                variant={"outlined"}
                                name="surname"
                                label={t({
                                    id: "cf-surname",
                                    message: "Cognome"
                                })}
                                control={this.props.control}
                                trigger={this.props.trigger}
                                formState={this.props.formState}
                                disabled={!this.checkUserCanEditCustomer()} />
                        </Grid>
                    </>}
                    <Grid item xs={12}>
                        <MatesTextField
                            variant={"outlined"}
                            name="fiscalCode"
                            label={t({
                                id: "cf-fiscal-code",
                                message: "Codice fiscale"
                            })}
                            control={this.props.control}
                            trigger={this.props.trigger}
                            formState={this.props.formState}
                            disabled={!this.checkUserCanEditCustomer()} />
                    </Grid>
                    <Grid item xs={6}>
                        <MatesSelect
                            name="geoArea"
                            variant={"outlined"}
                            label={t({
                                id: "cf-geo-area",
                                message: "Area Geografica di residenza"
                            })}
                            control={this.props.control}
                            formState={this.props.formState}
                            trigger={this.props.trigger}
                            disabled={!this.checkUserCanEditCustomer()}>

                            {MainConstants.GEO_AREA.map((item) => (<option value={item.key}
                                key={item.key}>{currentLanguage === "IT" ? item.label : item.labelEn}</option>))}
                        </MatesSelect>
                    </Grid>
                    <Grid item xs={6}>
                        <Stack direction={"row"} spacing={1} style={{
                            display: "inline-flex",
                            alignItems: "center",
                            width: "100%"
                        }}>
                            <MatesSelect
                                name={!this.props.readOnly ? "mainActivity" : "additionalInfo.mainActivity"}
                                variant={"outlined"}
                                label={t({
                                    id: "cf-main-activity",
                                    message: "Prevalente attività"
                                })}
                                control={this.props.control}
                                formState={this.props.formState}
                                trigger={this.props.trigger}
                                disabled={!this.checkUserCanEditCustomer()}>
                                {MainConstants.CUSTOMER_MAIN_ACTIVITY.map(
                                    (item) => (<option value={item.key}
                                        key={item.key}>{currentLanguage === "IT" ? item.label : item.labelEn}</option>))}
                            </MatesSelect>
                            <InfoIcon
                                sx={{
                                    color: "blue",
                                    cursor: "pointer"
                                }}
                                onClick={this.setMainActivityInfoModalVisibility}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <MatesSwitch
                            name={!this.props.readOnly ? "collaborative" : "additionalInfo.collaborative"}
                            label={t({
                                id: "cf-collaborative",
                                message: "Il cliente si è dimostrato collaborativo e trasparente"
                            })}
                            control={this.props.control}
                            formState={this.props.formState}
                            disabled={!this.checkUserCanEditCustomer()}
                            trigger={this.props.trigger} />
                    </Grid>
                    <Grid item xs={12}>
                        <MatesSelect
                            variant={"outlined"}
                            name="legalNature"
                            label={t({
                                id: "cf-legal-nature",
                                message: "Natura Giuridica"
                            })}
                            onChange={(e) => {
                                this.setState(() => ({ isContactDataChange: true }));
                                return e
                            }}
                            control={this.props.control}
                            formState={this.props.formState}
                            trigger={this.props.trigger}
                            disabled={!this.checkUserCanEditCustomer()}>

                            {MainConstants.CUSTOMER_LEGAL_NATURE_LIST.map(
                                (item) => (<option value={item.key}
                                    key={item.key}>{currentLanguage === "IT" ? item.label : item.labelEn}</option>))}

                        </MatesSelect>
                    </Grid>
                    <Grid item xs={6}>
                        <MatesTextField
                            name="contactName"
                            variant={"outlined"}
                            label={t({
                                id: "cf-contact-name",
                                message: "Nome di contatto"
                            })}
                            onChange={(e) => {
                                this.setState(() => ({ isContactDataChange: true }));
                                return e
                            }}
                            control={this.props.control}
                            formState={this.props.formState}
                            trigger={this.props.trigger}
                            disabled={!this.checkUserCanEditCustomer()}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <MatesTextField
                            name="contactSurname"
                            variant={"outlined"}
                            label={t({
                                id: "cf-contact-surname",
                                message: "Cognome di contatto"
                            })}
                            onChange={(e) => {
                                this.setState(() => ({ isContactDataChange: true }));
                                return e
                            }}
                            control={this.props.control}
                            formState={this.props.formState}
                            trigger={this.props.trigger}
                            disabled={!this.checkUserCanEditCustomer()}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <MatesTextField
                            name="contactPhone"
                            variant={"outlined"}
                            label={t({
                                id: "cf-phone",
                                message: "Cellulare"
                            })}
                            onChange={(e) => {
                                this.setState(() => ({ isContactDataChange: true }));
                                return e
                            }}
                            control={this.props.control}
                            formState={this.props.formState}
                            trigger={this.props.trigger}
                            disabled={!this.checkUserCanEditCustomer()}
                            endAdornment={<InputAdornment position="end">
                                <PhoneIcon />
                            </InputAdornment>}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <MatesTextField
                            name="email"
                            variant={"outlined"}
                            label={t({
                                id: "cf-email",
                                message: "Indirizzo e-mail"
                            })}
                            onChange={(e) => {
                                this.setState(() => ({ isContactDataChange: true }));
                                return e
                            }}
                            control={this.props.control}
                            formState={this.props.formState}
                            trigger={this.props.trigger}
                            disabled={!this.checkUserCanEditCustomer()}
                            endAdornment={<InputAdornment position="end">
                                <EmailIcon />
                            </InputAdornment>}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <MatesTextField
                            name="managementCode"
                            variant={"outlined"}
                            label={t({
                                id: "cf-management-code",
                                message: "Codice Gestionale"
                            })}
                            onChange={(e) => {
                                this.setState(() => ({ isContactDataChange: true }));
                                return e;
                            }}
                            control={this.props.control}
                            formState={this.props.formState}
                            trigger={this.props.trigger}
                            disabled={!this.checkUserCanEditCustomer()}
                        />
                    </Grid>
                    {!this.props.readOnly && <Grid item xs={12} textAlign="right">
                        <Button
                            color="primary"
                            variant="contained"
                            disabled={this.props.formState.isSubmitting || !this.props.formState.isValid || this.state.isLoading || !this.customIsValid()}
                            type="submit">
                            <Trans id={"cf-go-ahead"}>
                                Avanti
                            </Trans>
                        </Button>
                    </Grid>}
                    {this.props.readOnly && <Grid item xs={12} textAlign="right">
                        <Button
                            onClick={this.saveChanges}
                            color="primary"
                            variant="contained"
                            disabled={!this.state.isContactDataChange}>
                            <Trans id={"cf-save"}>Salva</Trans>
                        </Button>
                    </Grid>}
                </Grid>
            </form>}
        </>);
    }
}

const componentWithForm = withUseFormHook<{}>(CustomerForm, CustomerSchema, {
    mainActivity: "NORMAL",
    geoArea: "ITALY"
});

export default withSnackbar(componentWithForm);
