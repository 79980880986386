import {RestService} from "@sinossi/mates-react-library";
import {PurchaseableElement} from "../pages/models";
import {PurchaseableElementFilter} from "../pages/market/models";
import {ElementBuyResponse} from "../components/purcheaseable-elements/purcheaseable-element-card/models";
import {OrderListResponse} from "../pages/order-history/models";


export class PurcheaseableElementService{

    static getPurcheaseableElementList = (request: PurchaseableElementFilter, callback: any) => {

        RestService.post<PurchaseableElement>("/api/purchases/list", request)
            .then(callback);
    };

    static buyElement= (id : string, callback: any) => {
        RestService.get<ElementBuyResponse>(`/api/orders/buy/${id}`).then(callback);
    };

    static getOrders= (callback: any) => {
        RestService.get<OrderListResponse>(`/api/purchases/orders`).then(callback);
    }

}