import React from "react";

import {
	ManageUsersProps,
	ManageUsersState
} from "./models";

import { OperationService } from "../../services/operation/operation.service";

import {
	ErrorResponse,
	Loading,
	RestResponse
} from "@sinossi/mates-react-library";

import { WorkersListResponse } from "../../components/operations/risk-recap/models";

import {
	withSnackbar,
	WithSnackbarProps
} from "notistack";
import {
	t,
	Trans
} from "@lingui/macro";
import {
	Box,
	Fab,
	Typography
} from "@mui/material";
import { UsersTable } from "../../components/manage-users/users-table/users-table";
import AddIcon from "@mui/icons-material/Add";
import CreateUserModal from "../../components/manage-users/create-user-modal/create-user-modal";
import { UsersService } from "../../services/users.service";
import {
	User,
	UserRole
} from "../models";

class ManageUsers extends React.Component<ManageUsersProps & WithSnackbarProps, ManageUsersState>
{

	constructor(props: any)
	{
		super(props);

		this.state = {
			loading: true,
			isAddUserModalOpen: false,
			officeUsersList: []
		};
	}

	private getWorkersCallback = (response: RestResponse<WorkersListResponse, ErrorResponse>) => {
		if(!response.hasError()) {
			this.setState(() => ({
				officeUsersList: response.data?.list,
				loading: false
			}));
		} else {
			this.props.enqueueSnackbar(t({
				id: "mu-error-loading-users",
				message: "Errore nel caricamento degli utenti"
			}), {
				variant: "error",
			});
		}
	};

	componentDidMount()
	{
		OperationService.getOfficeWorkers(this.getWorkersCallback);
	}

	openAddUserModal = () => {
		this.setState(() => ({isAddUserModalOpen: true}));
	};

	closeAddUserModal = () => {
		this.setState(() => ({
			isAddUserModalOpen: false
		}));
	};

	addUserCallback = (response: any) => {

		this.setState(() => ({loading: false}));

		if(response.status !== 200) {

			this.props.enqueueSnackbar(t({
				id: "mu-error-close-snackbar",
				message: "Errore nella creazione dell'utente"
			}), {
				variant: "error",
			});

		} else {

			this.props.enqueueSnackbar(t({
				id: "mu-success-close-snackbar",
				message: "Utente creato"
			}), {
				variant: "success",
			});

			OperationService.getOfficeWorkers(this.getWorkersCallback);

		}
	};

	addUser = (user: any) => {

		this.setState(() => ({
			isAddUserModalOpen: false,
			loading: true
		}));

		UsersService.createUser(user, this.addUserCallback);

	};

	deleteUser = (row: User): void => {

		console.log(row.id);

		UsersService.deleteUser(row.id, this.deleteUserCallback);

	};

	editUser = (id: string, role: UserRole): void => {
		UsersService.changeRole(id, role, this.editUserCallback);
	};

	editUserCallback = (response: RestResponse<any, ErrorResponse>) => {
		if(response.hasError()) {
			this.props.enqueueSnackbar(t({
				id: "mu-error-change-role",
				message: "Errore nel cambio di ruolo"
			}), {
				variant: "error",
			});
			return;
		}
		OperationService.getOfficeWorkers(this.getWorkersCallback);
	};

	deleteUserCallback = (response: RestResponse<any, ErrorResponse>) => {
		if(response.hasError()) {
			this.props.enqueueSnackbar(t({
				id: "mu-error-edit-users",
				message: "Errore nell'eliminazione dell'utente"
			}), {
				variant: "error",
			});
			return;
		}

		OperationService.getOfficeWorkers(this.getWorkersCallback);
	};

	closeCUM = () => {
		this.setState(() => ({
			isAddUserModalOpen: false
		}));

	};

	render()
	{
		return (

			<>
				<Box component="div" className="main-container">
					<Loading show={this.state.loading} />
					<CreateUserModal isOpen={this.state.isAddUserModalOpen}
						handleUserLoad={this.addUser}
						handelClose={this.closeAddUserModal} />
					<Typography variant="h3" sx={{mb: 3}}>
						<Trans id="mu-header"> Utenti studio </Trans>
					</Typography>
					<UsersTable usersList={this.state.officeUsersList}
						deleteUser={this.deleteUser}
						editUser={this.editUser} />
					<Fab id={"action-fab"} color="secondary" onClick={this.openAddUserModal}>
						<AddIcon />
					</Fab>
				</Box>
			</>);
	}
}

export default withSnackbar(ManageUsers);
