import React from "react";
import {
    Box,
    Button,
    Grid,
    Typography
} from "@mui/material";
import { t, Trans } from "@lingui/macro";
import {
    ErrorResponse,
    Loading,
    NetworkService,
    RestResponse
} from "@sinossi/mates-react-library";
import { withRouter } from "react-router";
import "./finalize-data.css";
import {
    Customer,
    RequiredDocumentType
} from "../models";
import { Redirect } from "react-router-dom";
import {
    FinalizeDataProps,
    FinalizeDataState
} from "./models";
import RequestedDocument from "./requested-document/requested-document";
import { CustomersService } from "../../services/customers.service";
import { withSnackbar, WithSnackbarProps } from "notistack";

class FinalizeData extends React.Component<FinalizeDataProps & WithSnackbarProps, FinalizeDataState> {

    constructor(props: any) {
        super(props);

        this.state = {
            uploadDocumentList: [],
            loading: true,
            customer: null,
            onError: false,
            onSuccess: false,
            privacyAcceptance: false,
            uploadedDocuments: 0
        };

    }

    componentDidMount() {

        NetworkService.removeAuthenticationToken();

        let customerId = this.props.match.params.id;

        let params = (new URL(window.location.href)).searchParams;
        let authCode = params.get("authString");

        CustomersService.getCustomerExternal(customerId, authCode, this.getCustomerCallback);
    }

    private getCustomerCallback = (response: RestResponse<Customer, ErrorResponse>) => {
        if (response.hasError()) {
            this.setState(() => ({ onError: true }));
        } else {
            this.setState(() => ({
                customer: response.data,
                loading: false
            }));
        }
    };

    private finalizeData = () => {

        this.setState(() => ({ loading: true }));

        if (this.state.uploadDocumentList?.[0] != null) {
            this.uploadDocument(this.state.uploadDocumentList[0], 0);
        }
    };

    private uploadCompleteCallback = (response: RestResponse<null, ErrorResponse>) => {

        this.setState(() => ({ loading: false }));

        if (!response.hasError()) {
            this.setState(() => ({ onSuccess: true }));
        } else {
            this.setState(() => ({ onError: true }));
        }

    };

    private uploadDocument = (document: any, index: number) => {

        if (index === this.state.uploadDocumentList!.length) {
            CustomersService.confirmInsertedData(this.state.customer?.id, this.state.customer?.additionalInfo.authCode,
                this.uploadCompleteCallback);
            return;
        }

        let request = {
            documentType: document.documentType,
            documentName: document.name,
            referencedEntityId: (document.person === undefined || document.person === null) ? this.state.customer?.id : document.person.id,
            issueDate: document.issueDate,
            expiryDate: document.expiryDate,
            issuer: document.issuer,
            number: document.number,
            file: null
        };

        let uploadDocumentSuccessCallback = () => {
            ++index;
            this.uploadDocument(this.state.uploadDocumentList?.[index], index);
        };

        let uploadDocumentErrorCallback = (error: any) => {
            console.log(error);
            this.setState(() => ({ loading: false }));
            this.props.enqueueSnackbar(t({
                id: "fd-error-finalization",
                message: "Errore nel termine della procedura"
            }), {
                variant: "error",
            });
        };

        let params = (new URL(window.location.href)).searchParams;
        let authCode: string = params.get("authString") || "";
        let customerId: string = params.get("customerId") || "";

        NetworkService.removeAuthenticationToken();

        CustomersService.uploadDocumentExternal(authCode, customerId, document.file, request,
            uploadDocumentSuccessCallback, uploadDocumentErrorCallback);
    };

    private handleFileUpload = (document: any) => {

        let numberUploadedDocuments = this.state.uploadedDocuments + 1;
        let tempUploadDocumentList = this.state.uploadDocumentList;

        tempUploadDocumentList?.push(document);
        this.setState(() => ({
            uploadDocumentList: tempUploadDocumentList,
            uploadedDocuments: numberUploadedDocuments
        }));
    };

    private handleDelete = (id: any) => {

        let tempUploadDocumentList = this.state.uploadDocumentList;
        let numberUploadedDocuments = this.state.uploadedDocuments - 1;

        if (tempUploadDocumentList != null) {

            let removeIndex = -1;
            for (let i = 0; i < tempUploadDocumentList?.length; i++) {
                if (tempUploadDocumentList?.[i].id === id) {
                    removeIndex = i;
                }
            }

            tempUploadDocumentList!.splice(removeIndex, 1);

            this.setState(() => ({
                uploadDocumentList: tempUploadDocumentList,
                uploadedDocuments: numberUploadedDocuments
            }));
        }
    };

    private correctNumberUploadedDocuments = (): boolean => {

        let numberRequiredDocuments = 0;

        if (this.state.customer?.additionalInfo.requiredDocuments != null) {
            numberRequiredDocuments += this.state.customer?.additionalInfo.requiredDocuments.length as number;
        }

        if (this.state.customer?.ownerList != null) {
            for (let i = 0; i < this.state.customer.ownerList.length; i++) {
                if (this.state.customer?.ownerList[i].additionalInfo?.requiredDocumentList != null) {
                    numberRequiredDocuments += this.state.customer?.ownerList[i].additionalInfo?.requiredDocumentList?.length as number;
                }
            }
        }

        console.log(numberRequiredDocuments);
        return this.state.uploadedDocuments === numberRequiredDocuments;
    };

    render() {
        return (<>
            {this.state.loading && <Loading show />}
            {this.state.onError && <Redirect to={"/identification-error"} />}
            {this.state.onSuccess && <Redirect to={"/identification-success"} />}
            <Typography variant="h3" className={"text-center"}>
                <Trans id="finalize-data.header">
                    Completa la procedura
                </Trans>
            </Typography>
            <Typography variant="body1" className={"text-center"} sx={{ mt: 2 }}>
                <Trans id="customers-onboard.msg">
                    Gentile Cliente, inserisci i documenti richiesti per completare la procedura di identificazione
                </Trans>
            </Typography>
            <Box sx={{
                backgroundColor: "#FAFBFB",
                p: 2,
                mt: 2
            }} className="finalize-data-container">
                <Grid container>
                    <Grid item xs={12}>
                        {this.state.customer?.ownerList?.map(
                            (item: any) => ((item.additionalInfo.requiredDocumentList).map(
                                (document: RequiredDocumentType, index: number) => (<RequestedDocument
                                    document={document}
                                    isPerson={true}
                                    person={item}
                                    key={index}
                                    myKey={"field" + Math.random() * 100}
                                    fileUploadFunction={this.handleFileUpload}
                                    removeDocument={this.handleDelete} />))))}
                        {this.state.customer?.additionalInfo?.requiredDocuments!.map(
                            (item: RequiredDocumentType, index: number) => (<RequestedDocument
                                document={item}
                                isPerson={false}
                                key={index}
                                myKey={"field" + Math.random() * 100}
                                fileUploadFunction={this.handleFileUpload}
                                removeDocument={this.handleDelete} />))}
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 3 }} textAlign={"center"}>
                        <Button
                            color="primary"
                            variant="contained"
                            disabled={!this.correctNumberUploadedDocuments()}
                            onClick={this.finalizeData}>
                            <Trans id={"fd-identification-end"}>
                                Termina identificazione
                            </Trans>
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </>);
    }
}

export default withSnackbar(withRouter(FinalizeData));
