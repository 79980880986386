import React from "react";
import {
	MandateModalProps,
	MandateModalState
} from "./models";
import {
	Button,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Fab,
	Stack,
	Typography
} from "@mui/material";
import { Trans } from "@lingui/macro";
import { MandateType } from "../../../pages/models";
import UploadIcon from "@mui/icons-material/Upload";
import CancelIcon from "@mui/icons-material/Cancel";

export default class MandateModal extends React.Component<MandateModalProps, MandateModalState>
{
	private handleClose = () => {

		let closingData = {
			document: this.state.document,
			mandateType: MandateType.DOCUMENT
		};

		this.props.closeModal(closingData);
	};

	private handleRevert = () => {

		this.props.closeModal(null);
	};

	constructor(props: any)
	{
		super(props);

		this.state = {
			document: {}
		};
	}

	private parseFile = (event: any) => {
		this.setState(() => ({document: event.target.files[0]}));
	};

	private removeFile = () => {
		this.setState(() => ({document: {}}));
	};

	render()
	{
		return (<>
			<DialogTitle color={"primary"}>
				<Trans id={"mm-title"}> Dati del mandato </Trans>
			</DialogTitle>
			<DialogContent>
				<DialogContentText>
					<Trans id={"mm-text"}>
						Inserisci i dati del mandato che è stato conferito allo studio per la gestione della sua pratica
					</Trans>
				</DialogContentText>
				{<Stack direction={"row"} alignItems={"baseline"} sx={{mt: 3}}>
					<label>
						<input
							style={{display: "none"}}
							name="upload-file"
							type="file"
							onChange={this.parseFile} />
						<Fab
							color="secondary"
							size="small"
							component="span"
							aria-label="add"
							variant="extended"
							disabled={this.state.document.filename != null}>
							<UploadIcon /> <Trans id={"mm-upload-file"}> Carica File </Trans>
						</Fab>
					</label>
					{this.state.document.name != null && <Stack direction={"row"} alignItems={"baseline"}>
						<Typography variant={"body1"}
							sx={{ml: 3}}> {this.state.document.name} </Typography>
						<CancelIcon color="error"
							sx={{ml: 2}}
							onClick={this.removeFile}
							cursor={"pointer"} />
					</Stack>}
				</Stack>}
				<DialogActions>
					<Button onClick={this.handleRevert}> <Trans id="mm-close"> Chiudi</Trans> </Button>
					{<Button onClick={this.handleClose} variant="contained" color={"primary"}>
						<Trans id="mm-upload"> Salva </Trans> </Button>}
				</DialogActions>
			</DialogContent>
		</>);
	}
}