import React from "react";
import {
	OrderHistoryElementProps,
	OrderHistoryElementState
} from "./models";
import { withRouter } from "react-router-dom";
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from "@mui/material";
import { DateTime } from "luxon";

class OrderHistoryTable extends React.Component<OrderHistoryElementProps, OrderHistoryElementState>
{

	private formatItalianDate = (value: string | null) => {

		if(value !== null) {
			return DateTime.fromMillis(Date.parse(value)).toFormat("dd/MM/yyyy");
		}
	};

	render()
	{
		return (<>
				<TableContainer component={Paper}>
					<Table sx={{minWidth: 650}} aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell>Descrizione</TableCell>
								<TableCell align="right">Tipologia</TableCell>
								<TableCell align="right">Imponibile</TableCell>
								<TableCell align="right">IVA</TableCell>
								<TableCell align="right">Prezzo</TableCell>
								<TableCell align="right">Data</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{this.props.order?.map((row) => (<TableRow
									key={row.id}
									sx={{"&:last-child td, &:last-child th": {border: 0}}}
								>
									<TableCell component="th" scope="row">
										{row.purchaseableElement.displayName}
									</TableCell>
									<TableCell align="right">{row.purchaseableElement.type}</TableCell>
									<TableCell align="right">{row.amountExcludingVat}€</TableCell>
									<TableCell align="right">{row.appliedVatPercentage}%</TableCell>
									<TableCell align="right">{row.total}€</TableCell>
									<TableCell align="right">{this.formatItalianDate(row.date)}</TableCell>
								</TableRow>))}
						</TableBody>
					</Table>
				</TableContainer>
			</>);
	}
}

export default withRouter(OrderHistoryTable);