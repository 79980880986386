import {
	User,
	UserRole
} from "../pages/models";
import {
	NetworkParams,
	NetworkService,
	RestService
} from "@sinossi/mates-react-library";
import { ChangePasswordRequest } from "../pages/customer-change-password/model";

export class UsersService
{

	static registerUser = (request: User, callback: any) => {

		localStorage.removeItem(NetworkParams.DEFAULT_CONFIG.storageTokenName);

		RestService.post<User>("/api/users/register", request)
			.then(callback);
	};

	static createUser = (request: any, callback: any) => {

		NetworkService.post("/api/users/create", JSON.stringify(request))
			.then(callback);
	};

	static changePassword = (request: ChangePasswordRequest, callback: any) => {

		NetworkService.put("/api/users/change-password", JSON.stringify(request))
			.then(callback);
	}

	static deleteUser = (id: string, callback: any) => {

		RestService.delete<User>(`/api/users/${id}`)
			.then(callback);
	}

	static changeRole = (id: string,  role: UserRole, callback: any) => {
		RestService.post<User>(`/api/users/${id}/change-role`, role)
			.then(callback);

	}
}
