import React from "react";
import {
	Link,
} from "react-router-dom";

import {
	NavigationBackProps,
	NavigationBackState
} from "./models";
import {
	Box,
	Fab
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default class NavigationBack extends React.Component<NavigationBackProps, NavigationBackState>
{
	render()
	{
		return (<>
			<Box className={"w-100"} textAlign={"left"}>
				<Link to={this.props.backRoute}>
					<Fab color="primary" size="small">
						<ArrowBackIcon color={"secondary"} />
					</Fab>
				</Link>
			</Box>
		</>);
	}
}