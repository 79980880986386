import React from "react";

import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	Grid,
	Typography,
} from "@mui/material";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import {
	t,
	Trans
} from "@lingui/macro";
import { i18n } from "@lingui/core";

export class PrivacyAgreement extends React.Component<{}, { privacyAcceptance: boolean, efestoIdentificationLink: string }>
{

	constructor(props: Readonly<{}> | {})
	{
		super(props);
		this.state = {
			privacyAcceptance: false,
			efestoIdentificationLink: ""
		};
	}

	componentDidMount()
	{
		let params = (new URL(window.location.href)).searchParams;

		let linkBase64: string = params.get("payload") || "";

		let linkBytes = Buffer.from(linkBase64, "base64");
		let linkToString = linkBytes.toString("binary");

		this.setState(() => ({efestoIdentificationLink: linkToString}));

		let lang: string = params.get("lang") || "IT";

		localStorage.setItem("selected_language", lang);
		i18n.activate(lang);
	}

	private handlePrivacyChange = () => {
		this.setState(() => ({privacyAcceptance: !this.state.privacyAcceptance}));
	};

	private openPolicy = () => {
		window.open(((localStorage.getItem(
			"selected_language") || "IT") === "IT" ? process.env.REACT_APP_PRIVACY_LINK_IT : process.env.REACT_APP_PRIVACY_LINK_EN) || "about:blank",
			"_blank");
	};

	private openLink = () => {
		window.location.href = this.state.efestoIdentificationLink;
	};

	render()
	{
		return (<Box
			sx={{
				marginTop: 8,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
			}}
		>
			<HelpOutlineIcon color="success" sx={{fontSize: 120}} />
			<Typography mt={2} mb={2} variant="h3" textAlign={"center"}>
				<Trans id="pa-title">
					Accettazione Richiesta
				</Trans>
			</Typography>
			<Typography variant="h5" textAlign={"center"}>
				<Grid>
					<Grid item xs={12} sx={{mt: 3}}>
						<Button
							color="primary"
							variant="outlined"
							onClick={this.openPolicy}>
							<Trans id={"pa-open-policy"}>
								Privacy Policy
							</Trans>
						</Button>
					</Grid>
					<Grid item xs={12} sx={{mt: 3}}>
						<FormControlLabel
							control={<Checkbox checked={this.state.privacyAcceptance}
								onChange={this.handlePrivacyChange}
								name="privacy" />}
							label={t({
								id: "fd-privacy-clause",
								message: "Confermo di aver letto e compreso le Condizioni sulla Privacy"
							})} />
					</Grid>
					<Grid item xs={12} sx={{mt: 3}}>
						<Button
							color="primary"
							variant="contained"
							disabled={!this.state.privacyAcceptance}
							onClick={this.openLink}>
							<Trans id={"pa-open-link"}>
								Procedi al modulo di identificazione
							</Trans>
						</Button>
					</Grid>
				</Grid>
			</Typography>
		</Box>);
	}
}
