import React from "react";
import { UseFormReturn } from "react-hook-form";

import * as Yup from "yup";

import {
	CustomerSearchFormProps,
	CustomerSearchFormState
} from "./models";

import { CustomersFilter } from "../../../pages/customers/models";

import {
	Fab,
	Grid,
	Stack,
	Tooltip,
	Typography
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import {
	MatesSelect,
	MatesTextField,
	withUseFormHook
} from "@sinossi/mates-react-library";
import {
	t,
	Trans
} from "@lingui/macro";
import { MainConstants } from "../../../pages/models";

const CustomerSearchFormSchema = Yup.object().shape({
	managementCode: Yup.string().nullable(),
	name: Yup.string().nullable(),
	worker: Yup.string().nullable(),
	vatCode: Yup.string().nullable(),
	status: Yup.string()
});

class CustomerSearchForm extends React.Component<CustomerSearchFormProps & UseFormReturn, CustomerSearchFormState>
{
	constructor(props: any)
	{
		super(props);

		this.state = {
			loading: true,
			showHidden: false
		};
	}

	componentDidMount()
	{

		console.log(this.props.searchFormValues);

		if(this.props.searchFormValues !== null) {
			this.props.reset({
				managementCode: this.props.searchFormValues.managementCode !== null ? this.props.searchFormValues.managementCode : "",
				name: this.props.searchFormValues.name !== null ? this.props.searchFormValues.name : "",
				worker: this.props.searchFormValues.worker !== null ? this.props.searchFormValues.worker : "",
				vatCode: this.props.searchFormValues.vatCode !== null ? this.props.searchFormValues.vatCode : "",
				status: this.props.searchFormValues.status !== null ? this.props.searchFormValues.status : ""
			});

			this.setState(() => ({showHidden: this.props.searchFormValues.deleted}));
		}

		this.setState(() => ({loading: false}));
	}

	private submitForm = (values: any) => {

		let customerFilter: CustomersFilter = {
			managementCode: values.managementCode !== "" ? values.managementCode : null,
			name: values.name !== "" ? values.name : null,
			worker: values.worker !== "" ? values.worker : null,
			vatCode: values.vatCode !== "" ? values.vatCode : null,
			office: null,
			deleted: this.state.showHidden,
			status: values.status !== "" ? values.status : null,
		};

		console.log(values);
		console.log(customerFilter);

		this.props.searchFunction(customerFilter);
	};

	private setShowHidden = () => {
		this.setState(() => ({
			showHidden: !this.state.showHidden
		}));
	};

	render()
	{
		let currentLanguage = window.localStorage.getItem("selected_language") || "IT";

		return (<>
			{!this.state.loading && <form onSubmit={this.props.handleSubmit(this.submitForm)}>
				<Grid item xs={12} sx={{mt: 2}}>
					<Grid container spacing={1}>
						<Grid item xs={2}>
							<MatesTextField
								name="name"
								label={t({
									id: "csf-denomination",
									message: "Denominazione"
								})}
								variant={"outlined"}
								control={this.props.control}
								formState={this.props.formState}
								trigger={this.props.trigger}
								disabled={false} />
						</Grid>
						<Grid item xs={2}>
							<MatesTextField
								name="vatCode"
								label={t({
									id: "csf-vat-or-fiscal-code",
									message: "CF/P.IVA"
								})}
								variant={"outlined"}
								control={this.props.control}
								formState={this.props.formState}
								trigger={this.props.trigger}
								disabled={false} />
						</Grid>
						<Grid item xs={2}>
							<MatesTextField
								name="managementCode"
								variant={"outlined"}
								label={t({
									id: "csf-management-code",
									message: "Codice Gestionale"
								})}
								control={this.props.control}
								formState={this.props.formState}
								trigger={this.props.trigger}
								disabled={false} />
						</Grid>
						<Grid item xs={2}>
							<MatesTextField
								name="worker"
								label={t({
									id: "csf-worker",
									message: "Professionista"
								})}
								variant={"outlined"}
								control={this.props.control}
								formState={this.props.formState}
								trigger={this.props.trigger} />
						</Grid>
						<Grid item xs={2}>
							<MatesSelect
								variant={"outlined"}
								name="status"
								label={t({
									id: "csf-status",
									message: "Stato del cliente"
								})}
								control={this.props.control}
								formState={this.props.formState}
								trigger={this.props.trigger}>
								{MainConstants.CUSTOMER_STATUS_LIST.map(
									(item) => (<option value={item.key} key={item.key}>{currentLanguage === "IT"? item.label : item.labelEn}</option>))}
							</MatesSelect>
						</Grid>
						<Grid item xs={2}>
							<Stack direction={"row"} spacing={2} sx={{ml: 2}}>
								<Tooltip title={t({
									id: "csf-hidden-tooltips",
									message: "Mostra clienti nascosti"
								})} arrow>
									<Fab color={this.state.showHidden ? "primary" : "secondary"}
										size={"small"}
										onClick={this.setShowHidden}>
										<VisibilityOffIcon />
									</Fab>
								</Tooltip>
								<Fab color="primary" aria-label="search" size={"small"} type="submit">
									<SearchIcon />
								</Fab>
							</Stack>
						</Grid>
						{this.state.showHidden && <Grid item xs={12} sx={{mt: 1}}>
							<Typography variant={"subtitle2"} color={"red"} fontSize={17}>
								<Trans id={"csf-hidden-selected"}>
									La ricerca sarà effettuata sui clienti nascosti
								</Trans>
							</Typography>
						</Grid>}
					</Grid>
				</Grid>
			</form>}</>);
	}
}

export default withUseFormHook<{}>(CustomerSearchForm, CustomerSearchFormSchema, {});
