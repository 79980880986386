import React from "react";
import {
	CustomerSummaryProps,
	CustomerSummaryState
} from "./models";
import CustomerRecapInformations from "components/customers/customer-recap-informations/customer-recap-informations";
import { CustomersService } from "services/customers.service";
import {
	Customer,
} from "../models";
import {
	ErrorResponse,
	Loading,
	RestResponse
} from "@sinossi/mates-react-library";
import {
	RouteComponentProps,
	withRouter,
} from "react-router-dom"
import {
	Box,
	Typography
} from "@mui/material";
import NavigationBack from "components/navigation/navigation-back/navigation-back";
import { Trans } from "@lingui/macro";
import ErrorIcon from "@mui/icons-material/Error";

class CustomerSummary extends React.Component<CustomerSummaryProps & RouteComponentProps<any>, CustomerSummaryState>
{
	constructor(props: any)
	{
		super(props);

		this.state = {
			isLoading: true,
			onError: false,
			customer: null,
			backRoute: "/customers"
		};
	}

	private getCustomerCallback = (response: RestResponse<Customer, ErrorResponse>) => {

		if(response.hasError()) {
			this.setState(() => ({
				onError: true,
				isLoading: false
			}));
		} else {
			this.setState(() => ({
				customer: response.data,
				isLoading: false
			}));
		}

	};

	componentDidMount()
	{
		let params = (new URL(window.location.href)).searchParams;

		let route: string = params.get("backTo") ?? "/customers";

		this.setState(() => ({backRoute: route}));

		this.getCustomer();
	}

	private getCustomer = () =>
	{
		let customerId = this.props.match.params.id;

		CustomersService.getCustomer(customerId, this.getCustomerCallback);
	};

	render()
	{
		return (<Box component="div">
			<Loading show={this.state.isLoading} />
			<NavigationBack backRoute={this.state.backRoute} />
			<Typography variant="h3" textAlign={"center"}>
				<Trans id="csu-header">Dati Cliente</Trans>
			</Typography>
			<Box component="div"
				className="info-container"
				sx={{
					mt: 5,
					p: 4,
					backgroundColor: "#FAFBFB",
				}}>
				{!this.state.isLoading && !this.state.onError &&
					<CustomerRecapInformations customer={this.state.customer} isOnlyShow={false} getCustomer={this.getCustomer}/>}
				{this.state.onError &&
					<Box marginTop={4} textAlign={"center"}>
						<ErrorIcon color="error" sx={{fontSize: 45}} />
						<Typography variant="h5" color={"error"}>
							<Trans id="csu-error">
								Si è verificato un errore nella ricerca del cliente
							</Trans>
						</Typography>
					</Box>}
			</Box>
		</Box>);

	}
}

export default withRouter(CustomerSummary);
