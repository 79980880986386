import React from "react";

import { UseFormReturn } from "react-hook-form";

import {
	MatesTextField,
	withUseFormHook,
} from "@sinossi/mates-react-library";

import {
	Box,
	Button,
	Grid,
	Typography,
} from "@mui/material";

import {
	ChangePasswordRequest,
	CustomerChangePasswordProps,
	CustomerChangePasswordState
} from "./model";

import {
	withSnackbar,
	WithSnackbarProps
} from "notistack";

import {
	t,
	Trans
} from "@lingui/macro";
import * as Yup from "yup";
import { UsersService } from "../../services/users.service";

const CustomerChangePasswordSchema = Yup.object().shape({

	password: Yup.string().required(t({
		id: "ccp-password-valid-msg",
		message: "La password è obbligatoria"
	})),
	newPassword: Yup.string().required(t({
		id: "ccp-new-password-required-msg",
		message: "La nuova password è obbligatoria"
	})),
	newPasswordVerify: Yup.string().required(t({
		id: "cf-new-password-verify-required-msg",
		message: "È obbligatorio reinserire la password nuova"
	})),

});

export class CustomerChangePassword extends React.Component<CustomerChangePasswordProps & UseFormReturn & WithSnackbarProps, CustomerChangePasswordState>
{

	private changePasswordCallback = (response: any) => {
		if(response.status !== 200) {

			this.props.enqueueSnackbar(t({
				id: "ccp-error-change-password-error",
				message: "Si è verificato un errore. La password non è stata cambiata"
			}), {
				variant: "error",
			});

		} else {

			this.props.enqueueSnackbar(t({
				id: "ccp-success-change-password",
				message: "La password è stata cambiata correttamente"
			}), {
				variant: "success",
			});
		}
	}

	private submitForm = (values: any) => {

		if(values.newPassword !== values.newPasswordVerify) {
			this.props.enqueueSnackbar(t({
				id: "ccp-error-customer-change-password",
				message: "La nuova password è sbagliata"
			}), {
				variant: "error",
			});
		}
		else{
			let changePasswordRequest: ChangePasswordRequest = {
				password: values.password,
				newPassword: values.newPassword
			}
			UsersService.changePassword(changePasswordRequest, this.changePasswordCallback)

			values.password = null;
			values.newPassword = null;
		}
	};

	render()
	{
		return (
			<Box component="div" className="onboard-container summary-container">
			<Typography variant="h5" sx={{mb: 3}}>
				<Trans id="ccp-form-change-password">
					Cambio password
				</Trans>
			</Typography>
			<form onSubmit={this.props.handleSubmit(this.submitForm)}>
				<Grid container spacing={2}  justifyContent="end">
					<Grid item xs={12}>
						<MatesTextField
							variant={"outlined"}
							name="password"
							type={"password"}
							label={t({
								id: "ccp-password",
								message: "Password"
							})}
							control={this.props.control}
							trigger={this.props.trigger}
							formState={this.props.formState}/>
					</Grid>
					<Grid item xs={12}>
						<MatesTextField
							variant={"outlined"}
							name="newPassword"
							type={"password"}
							label={t({
								id: "ccp-new-password",
								message: "Nuova password"
							})}
							control={this.props.control}
							trigger={this.props.trigger}
							formState={this.props.formState}/>
					</Grid>
					<Grid item xs={12}>
						<MatesTextField
							variant={"outlined"}
							name="newPasswordVerify"
							type={"password"}
							label={t({
								id: "ccp-new-password-verify",
								message: "Ripeti nuova password"
							})}
							control={this.props.control}
							trigger={this.props.trigger}
							formState={this.props.formState}/>
					</Grid>
					<Grid item xs={1}>
						<Button
							sx={{mt: 1}}
							size={"medium"}
							color="primary"
							variant="contained"
							disabled={this.props.formState.isSubmitting}
							type="submit">
							<Trans id={"ccp-save"}>
								Salva
							</Trans>
						</Button>
					</Grid>
				</Grid>
			</form>
		</Box>);
	}
}

const componentWithForm = withUseFormHook<{}>(CustomerChangePassword, CustomerChangePasswordSchema);

export default withSnackbar(componentWithForm);