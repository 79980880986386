import React from "react";

import {
	CustomerRelatedPersonProps,
	CustomerRelatedPersonState
} from "./models";

import { PersonType } from "../../../../pages/models";
import PersonForm from "../../../person-form/person-form";

import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Typography
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonIcon from "@mui/icons-material/Person";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Trans } from "@lingui/macro";

export default class CustomerRelatedPerson extends React.Component<CustomerRelatedPersonProps, CustomerRelatedPersonState>
{
	render() {
		return (<> <Accordion id={"we" + Math.random()} sx={{ mt: 1 }}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header">
				<PersonIcon sx={{ mr: 2 }} />
				{this.props.person.name} {this.props.person.surname} - {this.props.person.type === PersonType.EXECUTOR &&
					<Typography sx={{
						fontWeight: "bold",
						ml: 1
					}}>
						<Trans id={"crp-executor"}>
							Esecutore
						</Trans>
					</Typography>}
				{this.props.person.type === PersonType.OWNER && <Typography
					sx={{
						fontWeight: "bold",
						ml: 1
					}}>
					<Trans id={"crp-owner"}>
						Titolare
					</Trans>
				</Typography>}
				{this.props.person.documentList && this.props.person.documentList.length > 0 ? (
					<InsertDriveFileIcon style={{ color: 'green', marginLeft: "5px" }} />
				) : (
					<InsertDriveFileIcon style={{ color: 'red', marginLeft: "5px" }} />
				)}
			</AccordionSummary>
			<AccordionDetails>
				<PersonForm person={this.props.person} readOnly={true} />
			</AccordionDetails>
		</Accordion>
		</>);
	}
}