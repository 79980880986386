import React from "react";
import {
	CustomersFilter,
	CustomersListResponse,
	CustomersProps,
	CustomersState
} from "./models";
import { withRouter } from "react-router-dom";
import {
	Box,
	Fab,
	Link,
	Typography
} from "@mui/material";
import {
	t,
	Trans
} from "@lingui/macro";
import {
	ErrorResponse,
	Loading,
	RestResponse
} from "@sinossi/mates-react-library";
import CustomersList from "../../components/customers/customers-list/customers-list";
import AddIcon from "@mui/icons-material/Add";
import "./customers.css";
import CustomerSearchForm from "../../components/customers/customer-search-form/customer-search-form";
import { CustomersService } from "../../services/customers.service";
import ErrorPageBox from "../../components/shared/error-page-box/error-page-box";
import EmptyListBox from "../../components/shared/empty-list-box/empty-list-box";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

class Customers extends React.Component<CustomersProps, CustomersState>
{

	constructor(props: any)
	{
		super(props);

		this.state = {
			response: null,
			errorConfig: {
				options: {showErrorTimestamp: true},
				showErrorCode: true
			},
			openError: false,
			isLoading: true,
			customerList: null,
			customerFilter: {
				name: null,
				vatCode: null,
				deleted: false,
				managementCode: null,
				worker: null,
				office: null,
				status: null
			},
			user: null,
			language: null
		};

	}

	componentDidMount()
	{
		CustomersService.getCustomersList(this.state.customerFilter, this.getCustomersListCallback);

		let currentLanguage = window.localStorage.getItem("selected_language") || "IT";

		let currentUserString = window.sessionStorage.getItem("currentUser");
		let currentUser: any = null;

		if(currentUserString != null) {
			currentUser = JSON.parse(currentUserString);
		}

		this.setState(() => ({
			user: currentUser,
			language: currentLanguage
		}));
	}

	private handleSearch = (filter: CustomersFilter) => {

		this.setState(() => ({
			customerFilter: filter,
			isLoading: true
		}));

		CustomersService.getCustomersList(this.state.customerFilter, this.getCustomersListCallback);
	};

	private getCustomersListCallback = (response: RestResponse<CustomersListResponse, ErrorResponse>) => {

		this.setState(() => ({response: response}));

		if(!response.hasError()) {

			this.setState(() => ({
				customerList: response.data!.list,
				isLoading: false
			}));

		} else {
			this.setState(() => ({openError: true}));
		}

	};

	closeErrorModal = () => {
		this.setState({openError: false});
	};

	goToCustomerVerify = (id: string) => {
		this.props.history.push(`/verify/${id}`);
	};

	refreshList = () => {
		CustomersService.getCustomersList(this.state.customerFilter, this.getCustomersListCallback);
	};

	private generateSearchReport = () => {

		let values = this.state.customerFilter;

		console.log(values);

		let currentUserString = window.sessionStorage.getItem("currentUser");

		if(currentUserString != null) {

			let charNum = 0;
			let currentUser = JSON.parse(currentUserString);

			let reportString = `/api/customers/search-report`;

			if(values.name !== null) {
				if(charNum === 0) {
					reportString += "?";
					charNum++;
				}
				reportString += `denomination=${values.name}`;
			}

			if(values.vatCode !== null) {
				if(charNum === 0) {
					reportString += "?";
					charNum++;
				} else {
					reportString += "&";
				}
				reportString += `vatCode=${values.vatCode}`;
			}

			if(values.managementCode !== null) {
				if(charNum === 0) {
					reportString += "?";
					charNum++;
				} else {
					reportString += "&";
				}
				reportString += `managementCode=${values.managementCode}`;
			}

			if(values.worker !== null) {
				if(charNum === 0) {
					reportString += "?";
					charNum++;
				} else {
					reportString += "&";
				}
				reportString += `worker=${values.worker}`;
			}

			if(values.status !== null) {
				if(charNum === 0) {
					reportString += "?";
					charNum++;
				} else {
					reportString += "&";
				}
				reportString += `status=${values.status}`;
			}

			if(values.deleted !== null) {
				if(charNum === 0) {
					reportString += "?";
					charNum++;
				} else {
					reportString += "&";
				}
				reportString += `deleted=${values.deleted}`;
			}

			reportString += `&officeId=${currentUser.office!.id}&lang=${window.localStorage.getItem("selected_language") || "IT"}`;

			window.location.href = reportString;

		}

	};

	render()
	{
		return (<Box component="div" className="main-container">
			{this.state.isLoading && <Loading show />}
			<Typography variant="h3">
				<Trans id="customers-list-page.header">
					Clienti
				</Trans>
			</Typography>
			{(!this.state.openError && !this.state.isLoading) && <>
				<CustomerSearchForm searchFunction={this.handleSearch}
					searchFormValues={this.state.customerFilter}
					sx={{mt: 1}} />
				{!this.state.isLoading &&
					<CustomersList list={this.state.customerList} refreshList={this.refreshList} user={this.state.user} />}
				<Fab id="pdf-fab" color="primary" size={"small"} onClick={this.generateSearchReport}>
					<PictureAsPdfIcon />
				</Fab>
				<Link href={"/customers/onboard"}>
					<Fab id="action-fab" color="secondary" aria-label="add">
						<AddIcon />
					</Fab>
				</Link>
			</>}
			{this.state.openError && <>
				<ErrorPageBox errorText={t({
					id: "cpage-error-list",
					message: "Si è verificato un errore nella ricerca dei clienti"
				})} />
			</>}
			{(!this.state.isLoading && this.state.customerList?.length === 0) && <>
				<EmptyListBox emptyText={t({
					id: "cpage-empty-list",
					message: "Non sono presenti clienti censiti"
				})} />
			</>}
		</Box>);
	}

}

export default withRouter(Customers);
