import {
    RestService
} from "@sinossi/mates-react-library";
import {Office} from "../pages/models";

export class OfficeService{

    static getUserOffice = (callback : any) =>{
        RestService.get<Office>(`/api/offices/get`).then(callback);
    }

}