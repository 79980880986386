export class DocumentService
{

	static downloadDocument = (id: string) => {


		window.open(`/api/documents/${id}/download`);
	};

}
