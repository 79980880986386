import { RestService } from "@sinossi/mates-react-library";
import { Mandate } from "../pages/models";

export class MandateService
{
	static setVerbalMandate = (analysisId: string | null, callback: any) => {
		RestService.put<Mandate>(`/api/analyses/${analysisId}/verbal-mandate`, {}).then(callback);
	};

	static setDocumentMandate = (analysisId: string | null, file: any, callback: any, errorCallback: any) => {

		let formData = new FormData();

		formData.append("file", file);

		fetch(`/api/analyses/${analysisId}/document-mandate`, {
			method: "PUT",
			body: formData
		})
			.then(response => {

				if(!response.ok) {
					errorCallback(response);
					return;
				}

				callback();
			})
			.catch(errorResponse => {
				errorCallback(errorResponse);
			});
	};
}