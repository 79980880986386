import React from "react";
import {withRouter} from "react-router-dom";

import {
    AnalysisCardProps,
    AnalysisCardState
} from "./models";

import {
    ConfirmDialog
} from "@sinossi/mates-react-library";

import {
    Button,
    Card,
    CardActions,
    CardContent,
    Stack,
    Typography
} from "@mui/material";

import LightbulbIcon from "@mui/icons-material/Lightbulb";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckIcon from "@mui/icons-material/Check";

import {DateTime} from "luxon";
import {
    t,
    Trans
} from "@lingui/macro";

class AnalysisCard extends React.Component<AnalysisCardProps, AnalysisCardState> {
    constructor(props: any) {
        super(props);

        this.state = {
            confirmDeleteDialogShow: false
        };
    }

    private confirmDeleteOpen = () => {
        this.setState(() => ({confirmDeleteDialogShow: true}));
    };

    private confirmDeleteCallback = () => {
        this.setState(() => ({confirmDeleteDialogShow: false}));
        this.props.emitDeleteCallback(this.props.analysis.id);
    };

    private formatItalianDate = (value: string | null) => {

        if (value !== null) {
            return DateTime.fromMillis(Date.parse(value)).toFormat("dd/MM/yyyy");
        }
    };

    goToSummary = () => {
        this.props.history.push("/analysis-summary/" + this.props.analysis.id);
    };

    render() {
        return (<Card variant="outlined" style={{minWidth: "250px"}}>
            <ConfirmDialog ok={this.confirmDeleteCallback}
                           open={this.state.confirmDeleteDialogShow}
                           cancel={() => this.setState(() => ({confirmDeleteDialogShow: false}))}
                           message={t({
                               id: "ac-delete-confirm-msg",
                               message: "Operazione di cancellazione dell'analisi. Procedere?"
                           })}
                           okText={t({
                               id: "ac-delete-confirm-btn",
                               message: "Conferma"
                           })}
                           cancelText={t({
                               id: "ac-delete-cancel-msg",
                               message: "Annulla"
                           })}
                           title={t({
                               id: "ac-delete-confirm-title",
                               message: "Conferma eliminazione"
                           })}/>
            <CardContent style={{width: "100%"}}>
                <Typography sx={{fontSize: 10}} color="text.secondary" gutterBottom>
                    {this.props.analysis.id}
                </Typography>
                <Stack direction={"row"} spacing={1} sx={{mb: 1}} style={{
                    display: "inline-flex",
                    alignItems: "center",
                    width: "100%"
                }}>
                    {this.props.analysis.closer === null && <LightbulbIcon/>}
                    {this.props.analysis.closer !== null && <CheckIcon color={"success"}/>}
                    <Typography variant="h6" component="div">
                        {this.formatItalianDate(this.props.analysis.startDate)}
                    </Typography>
                </Stack>
                <Typography variant="body1" component="div" sx={{mt: 1}}>
                    <b> <Trans id={"ac-operation-field"}>
                        Operazione
                    </Trans></b> <br/>
                    {this.props.analysis.operation?.shortDescription}
                </Typography>
                <Stack direction={"row"} spacing={1} sx={{mt: 1}} style={{
                    display: "inline-flex",
                    verticalAlign: "baseline",
                    width: "100%"
                }}>
                    <Typography variant="body1" component="div">
                        <b> <Trans id={"ac-mandate-field"}> Mandato </Trans> </b>
                    </Typography>
                    {this.props.analysis.mandate !== null && <CheckIcon color={"success"}/>}
                    {this.props.analysis.mandate === null && <HighlightOffIcon color={"error"}/>}
                </Stack>
                <Typography variant="body1" component="div" sx={{mt: 1}}>
                    <b> <Trans id={"ac-total-risk-field"}>
                        Rischio totale
                    </Trans></b> {this.props.analysis.riskValue?.total}
                </Typography>
            </CardContent>
            <CardActions>
                {this.props.analysis.closer === null && <Button size="small"
                                                                color={"error"}
                                                                variant={"outlined"}
                                                                sx={{mr: 1}}
                                                                onClick={this.confirmDeleteOpen}>
                    <Trans id={"ac-delete-action"}>
                        Elimina
                    </Trans>
                </Button>}
                <Button size="small" onClick={this.goToSummary}>
                    <Trans id={"ac-go-to-summary-action"}>
                        Riepilogo
                    </Trans>
                </Button>
            </CardActions>
        </Card>);
    }
}

export default withRouter(AnalysisCard);
