import React from "react";
import {
	RiskFactorTableProps,
	RiskFactorTableState
} from "./models";
import { withRouter } from "react-router-dom";
import {
	t,
	Trans
} from "@lingui/macro";
import {
	Table,
	TableBody,
	TableCell,
	TableRow,
	Typography
} from "@mui/material";
import "./risk-factor-table.css";

class RiskFactorTable extends React.Component<RiskFactorTableProps, RiskFactorTableState>
{

	constructor(props: any)
	{
		super(props);

		this.state = {
			loading: true
		};
	}

	componentDidMount()
	{
		this.setState(() => ({loading: false}));
	}

	createData = (name: string, value: any): any => {
		return {
			name,
			value
		};
	};

	createRows = (): any => {

		return [
			this.createData(t({
				id: "rft-client-risk",
				message: " Rischio connesso al cliente"
			}), this.props.riskFactor?.customerValue),
			this.createData(t({
				id: "rft-operation-risk",
				message: " Rischio connesso all'operazione"
			}), this.props.riskFactor?.operationValue),
			this.createData(t({
				id: "rft-total",
				message: " Totale"
			}), this.props.riskFactor?.total)
		];
	};

	selectBackgroundClass = () => {

		if(this.props.riskFactor === undefined) {
			return;
		}

		// @ts-ignore: Object is possibly 'null'.
		if(this.props.riskFactor!.total <= 20) {
			return "transparent";
		}
		// @ts-ignore: Object is possibly 'null'.
		if(this.props.riskFactor!.total > 20 && this.props.riskFactor!.total <= 30) {
			return "green";
		}
		// @ts-ignore: Object is possibly 'null'.
		if(this.props.riskFactor!.total > 30 && this.props.riskFactor!.total <= 40) {
			return "orange";
		}
		// @ts-ignore: Object is possibly 'null'.
		if(this.props.riskFactor!.total > 40) {
			return "red";
		}

		return "transparent";
	};

	private getRiskValueAsText = () => {

		if(this.props.riskFactor === undefined) {
			return;
		}

		// @ts-ignore: Object is possibly 'null'.
		if(this.props.riskFactor!.total <= 20) {
			return t({
				id: "rft-no-risk",
				message: "Rischio inesistente o assolutamente irrilevante"
			});
		}
		// @ts-ignore: Object is possibly 'null'.
		if(this.props.riskFactor!.total > 20 && this.props.riskFactor!.total <= 30) {
			return t({
				id: "rft-low-risk",
				message: "Rischio basso"
			});
		}
		// @ts-ignore: Object is possibly 'null'.
		if(this.props.riskFactor!.total > 30 && this.props.riskFactor!.total <= 40) {
			return t({
				id: "rft-moderate-risk",
				message: "Rischio moderato"
			});
		}
		// @ts-ignore: Object is possibly 'null'.
		if(this.props.riskFactor!.total > 40) {
			return t({
				id: "rft-high-risk",
				message: "Rischio alto"
			});
		}
	}

	render()
	{
		return (<>
			{!this.state.loading && <> <Table sx={{
				minWidth: 100,
				maxWidth: "100%"
			}}>
				<TableBody>
					{this.createRows().map((row: any) => (<TableRow
						key={row.name}
						sx={{"&:last-child td, &:last-child th": {border: 0}}}
					>
						<TableCell component="th" scope="row">
							{row.name}
						</TableCell>
						<TableCell align="center"
							sx={{
								fontSize: "1.2em",
								fontWeight: "bold",
								p: 2
							}}
							className={`${(row.name === "Totale") ? this.selectBackgroundClass() : ""}`}>{row.value}</TableCell>
					</TableRow>))}
				</TableBody>
			</Table>
				<Typography variant={"body1"} fontStyle={"italic"} sx={{p:2}}>
					<Trans id="rft-total-risk">Il valore totale indica un </Trans>
					 {this.getRiskValueAsText()}
				</Typography>
			</>}
		</>);
	}

}

export default withRouter(RiskFactorTable);