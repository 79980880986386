import {
	Route,
	Switch,
} from "react-router-dom";

import { Home, } from "pages";

import {
	PageForbidden,
	PageNotFound,
} from "pages/errors";

import { PaymentSuccess } from "./pages/payments/payment-success/payment-success";
import OfficeSetup from "./pages/office-setup/office-setup";
import Customers from "./pages/customers/customers";
import FinalizeData from "./pages/finalize-data/finalize-data";
import { IdentificationError } from "./pages/errors/identification-error/identification-error";
import { IdentificationSuccess } from "./pages/identification-success/identification-success";
import { CustomerCreateSuccess } from "./pages/customer-create-success/customer-create-success";
import VerifyData from "./pages/verify-data/verify-data";
import Analyses from "./pages/analyses/analyses";
import AnalysisSummary from "./pages/analysis-summary/analysis-summary";
import { CustomerVerifySuccess } from "./pages/customer-verify-success/customer-verify-success";
import OperationSummary from "./pages/operation-summary/operation-summary";
import { CreateOperation } from "./pages/create-operation/create-operation";
import Market from "./pages/market/market";
import OrderHistory from "./pages/order-history/order-history";
import CustomerSummary from "./pages/customer-summary/customer-summary";
import OperationCreateSuccess from "./pages/operation-create-success/operation-create-success";
import CustomerOnboard from "./pages/customer-onboard/customer-onboard";
import { PrivacyAgreement } from "./pages/privacy-agreement/privacy-agreement";
import CustomerChangePassword from "./pages/customer-change-password/customer-change-password";
import ManageUsers from "./pages/manage-users/manage-users";

interface ProtectedRoutesProp
{
	keycloak: any;
}

export class ProtectedRoute extends Route<ProtectedRoutesProp>
{
	private isLoggedIn = (): boolean => {
		let result = Boolean(this.props.keycloak) && Boolean(this.props.keycloak.authenticated);
		console.log("isLoggedIn: " + result);
		return result;
	};

	render()
	{
		if(!this.isLoggedIn()) {
			this.props.keycloak!.login({
				redirectUri: process.env.REACT_APP_BASE_URL + "/customers"
			});
		}

		return super.render();
	}
}

interface RoutesProp
{
	keycloak: any;
}

const routes = ({keycloak}: RoutesProp) => (<Switch>
	<Route exact path="/" render={() => <Home keycloak={keycloak} />} />
	{/*<Route exact path="/" component={OfficeSetup} />*/}
	<ProtectedRoute exact path="/setup-office" component={OfficeSetup} keycloak={keycloak} />
	<ProtectedRoute exact path="/customers" component={Customers} keycloak={keycloak} />
	<Route path="/end/:id" component={FinalizeData} />
	<ProtectedRoute exact path="/verify/:id" component={VerifyData} keycloak={keycloak} />
	<ProtectedRoute exact path="/identification-error" component={IdentificationError} keycloak={keycloak} />
	<ProtectedRoute exact path="/verify-success" component={CustomerVerifySuccess} keycloak={keycloak} />
	<ProtectedRoute exact path="/payment-success" component={PaymentSuccess} keycloak={keycloak} />
	<ProtectedRoute exact path="/customers/onboard" component={CustomerOnboard} keycloak={keycloak} />
	<ProtectedRoute exact
		path="/customer/:customerId/create-operation"
		component={CreateOperation}
		keycloak={keycloak} />
	<ProtectedRoute exact path="/customers/onboard/success" component={CustomerCreateSuccess} keycloak={keycloak} />
	<ProtectedRoute exact
		path="/customers/:customerId/create-operation/success"
		component={OperationCreateSuccess}
		keycloak={keycloak} />
	<ProtectedRoute exact path="/customers/change-password" component={CustomerChangePassword} keycloak={keycloak} />
	<ProtectedRoute exact path="/analyses/:id" component={Analyses} keycloak={keycloak} />
	<ProtectedRoute exact path="/analysis-summary/:id" component={AnalysisSummary} keycloak={keycloak} />
	<ProtectedRoute exact path="/operation-summary/:id" component={OperationSummary} keycloak={keycloak} />
	<ProtectedRoute exact path="/customer-summary/:id" component={CustomerSummary} keycloak={keycloak} />
	<ProtectedRoute exact path="/market" component={Market} keycloak={keycloak} />
	<ProtectedRoute exact path="/order-history" component={OrderHistory} keycloak={keycloak} />
	<ProtectedRoute exact path="/manage-users" component={ManageUsers} keycloak={keycloak} />
	<Route exact path="/forbidden" component={PageForbidden} />
	<Route exact path="/privacy-agreement" component={PrivacyAgreement} />
	<Route exact path="/identification-success" component={IdentificationSuccess} />
	<Route path="*" component={PageNotFound} />
</Switch>);

export default routes;
