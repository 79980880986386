import React from "react";
import {
	CreateUserModalProps,
	CreateUserModalState
} from "./models";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid
} from "@mui/material";
import {
	t,
	Trans
} from "@lingui/macro";
import { UseFormReturn } from "react-hook-form";
import {
	MatesSelect,
	MatesTextField,
	withUseFormHook
} from "@sinossi/mates-react-library";
import * as Yup from "yup";
import {
	MainConstants,
	User
} from "../../../pages/models";

const CreateUserModalSchema = Yup.object().shape({

	email: Yup.string().email(t({
		id: "cf-cs-email-valid-msg",
		message: "Inserire un indirizzo e-mail valido"
	})).required(t({
		id: "cf-cs-email-required-msg",
		message: "L'indirizzo e-mail è obbligatorio"
	})),
	name: Yup.string().required(t({
		id: "cum-name-required-msg",
		message: "Il nome è obbligatorio"
	})),
	surname: Yup.string().required(t({
		id: "cum-surname-required-msg",
		message: "Il nome è obbligatorio"
	})),
	role: Yup.string().required(t({
		id: "cum-role-required-msg",
		message: "Il ruolo è obbligatorio"
	}))
});

class CreateUserModal extends React.Component<CreateUserModalProps & UseFormReturn, CreateUserModalState>
{

	constructor(props: any)
	{
		super(props);

		this.state = {
			insertedUser: null,
			loading: true
		};
	}

	componentDidMount()
	{
		this.setState(() => ({loading: false}));
	}

	private submitForm = (values: any) => {

		console.log("Submitting form");

		let user: User = {
			creationDate: null,
			deleted: false,
			email: values.email,
			id: "",
			lastLogin: null,
			name: values.name,
			office: null,
			role: values.role,
			surname: values.surname
		};

		this.props.handleUserLoad(user);

	};

	private onValidationFailure = (data: any): void => {
		console.log("Validation failed");
		console.log(data);
	};

	render()
	{
		let currentLanguage = window.localStorage.getItem("selected_language") || "IT";

		return (<>
			<Dialog open={this.props.isOpen}>
				<DialogTitle color={"primary"}>
					<Trans id={"cum-title"}>
						Nuovo utente
					</Trans>
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<Trans id={"cum-text"}>
							Il nuovo utente riceverà una e-mail con i dati e le istruzioni di accesso.
							Attenzione: l’indirizzo e-mail inserito in questa fase diventerà lo username
							dell’utente e non è modificabile
						</Trans>
					</DialogContentText>
					{!this.state.loading &&
						<form onSubmit={this.props.handleSubmit(this.submitForm, this.onValidationFailure)}>
							<Grid container spacing={2} sx={{mt: 2}}>
								<Grid item xs={6}>
									<MatesTextField
										variant={"outlined"}
										name="name"
										label={t({
											id: "cum-name",
											message: "Nome"
										})}
										control={this.props.control}
										trigger={this.props.trigger}
										formState={this.props.formState}
									/>
								</Grid>
								<Grid item xs={6}>
									<MatesTextField
										variant={"outlined"}
										name="surname"
										label={t({
											id: "cum-surname",
											message: "Cognome"
										})}
										control={this.props.control}
										trigger={this.props.trigger}
										formState={this.props.formState}
									/>
								</Grid>
								<Grid item xs={6}>
									<MatesTextField
										variant={"outlined"}
										name="email"
										label={t({
											id: "cum-email",
											message: "E-Mail"
										})}
										control={this.props.control}
										trigger={this.props.trigger}
										formState={this.props.formState}
									/>
								</Grid>
								<Grid item xs={6}>
									<MatesSelect
										variant={"outlined"}
										name="role"
										label={t({
											id: "cum-role",
											message: "Ruolo"
										})}
										control={this.props.control}
										formState={this.props.formState}
										trigger={this.props.trigger}>

										{MainConstants.USER_ROLES.map(
											(item) => (<option value={item.key} key={item.key}>{currentLanguage === "IT"? item.label : item.labelEn}</option>))}

									</MatesSelect>
								</Grid>
							</Grid>
							<DialogActions>
								<Button onClick={this.props.handelClose}> <Trans id="mm-close"> Chiudi</Trans> </Button>
								{<Button
									variant="contained"
									color={"primary"}
									disabled={this.props.formState.isSubmitting}
									type="submit">
									<Trans id="cum-create"> Crea </Trans>
								</Button>}
							</DialogActions>
						</form>}
				</DialogContent>
			</Dialog>
		</>);
	}
}

export default withUseFormHook<{}>(CreateUserModal, CreateUserModalSchema, {});
