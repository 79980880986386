import React from "react"

import { Grid} from "@mui/material"

import { CustomersService } from "services/customers.service"

import CustomerCard from "../customer-card/customer-card";
import {
	CustomersListProps,
	CustomersListState
} from "./models";

export default class CustomersList extends React.Component<CustomersListProps, CustomersListState>
{
	private deleteCustomer = (customerId: string) => {
		CustomersService.deleteCustomer(customerId, this.customerDeleteCallback);
	};

	private customerDeleteCallback = () => {
		this.props.refreshList();
	};

	render()
	{
		return (<>
			<Grid container alignItems="stretch" spacing={3} marginTop={3}>
				{this.props.list?.map(
					(item, index) => ( <Grid item container key={item.id} lg={4} sm={6} xs={12} textAlign={"left"}><CustomerCard
						customer={item}
						emitDeleteCallback={this.deleteCustomer}
						user={this.props.user}/></Grid>))}
			</Grid>

		</>);
	}
}