import React from "react";
import {withRouter} from "react-router-dom";

import {
    AnalysisSummaryProps,
    AnalysisSummaryState
} from "./models";

import {
    Box,
    Button,
    Dialog,
    Grid,
    TextField,
    Typography
} from "@mui/material";

import {
    t,
    Trans
} from "@lingui/macro";

import {AnalysesService} from "../../services/analyses.service";
import {
    ConfirmDialog,
    ErrorResponse,
    Loading,
    RestResponse
} from "@sinossi/mates-react-library";

import {
    Analysis,
    MandateType,
    UserRole
} from "../models";

import RiskFactorTable from "../../components/analyses/risk-factor-table/risk-factor-table";
import AnalysisSummaryFields from "../../components/analyses/analysis-summary-fields/analysis-summary-fields";

import "./analysis-summary.css";

import MandateModal from "../../components/analyses/mandate-modal/mandate-modal";

import {MandateService} from "../../services/mandate.service";

import {
    withSnackbar,
    WithSnackbarProps
} from "notistack";

import NavigationBack from "../../components/navigation/navigation-back/navigation-back";

import DeleteIcon from "@mui/icons-material/Delete";
import {DateTime} from "luxon";

class AnalysisSummary extends React.Component<AnalysisSummaryProps & WithSnackbarProps, AnalysisSummaryState> {

    constructor(props: any) {
        super(props);

        this.state = {
            isLoading: true,
            openError: false,
            isMandateModalOpen: false,
            response: null,
            selectedAnalysisText: "",
            confirmDeleteDialogShow: false,
            showMandateButton: true,
            confirmClosedDialogShow: false,
            isClosed: false,
            errorConfig: {
                options: {showErrorTimestamp: true},
                showErrorCode: true
            },
            analysis: null,
            user: null
        };
    }

    componentDidMount() {
        AnalysesService.getAnalysis(this.props.match.params.id, this.getAnalysisCallback);

        let currentUserString = window.sessionStorage.getItem("currentUser");
        let currentUser: any = null;

        if (currentUserString != null) {
            currentUser = JSON.parse(currentUserString);
        }

        this.setState(() => ({
            user: currentUser
        }));
    }

    private getAnalysisCallback = (response: RestResponse<Analysis, ErrorResponse>) => {

        this.setState(() => ({response: response}));

        if (!response.hasError()) {

            this.setState(() => ({
                analysis: response.data,
                showMandateButton: response.data?.mandate === null,
                isLoading: false,
                isClosed: response.data?.closer !== null,
            }));

            this.setState(() => ({selectedAnalysisText: this.state.analysis!.textAnalysis}));

        } else {
            this.setState(() => ({openError: true}));
        }
    };

    private confirmDeleteOpen = () => {
        this.setState(() => ({confirmDeleteDialogShow: true}));
    };

    private confirmDeleteCallback = () => {
        this.setState(() => ({confirmDeleteDialogShow: false}));
        AnalysesService.deleteAnalysis(this.state.analysis?.id, this.deleteAnalysisCallback);
    };

    private deleteAnalysisCallback = () => {
        this.props.history.push("/analyses/" + this.state.analysis?.customer?.id);
    };

    private confirmCloseOpen = () => {
        this.setState(() => ({confirmClosedDialogShow: true}));
    };

    private confirmCloseCallback = () => {
        this.setState(() => ({confirmClosedDialogShow: false}));
        AnalysesService.closeAnalysis(this.props.match.params.id, this.closeAnalysisCallback);
    };

    private closeAnalysisCallback = (response: RestResponse<Analysis, ErrorResponse>) => {

        this.setState(() => ({response: response}));

        if (!response.hasError()) {

            this.setState(() => ({
                analysis: response.data,
                isLoading: false,
                isClosed: true,
            }));

            this.props.enqueueSnackbar(t({
                id: "as-success-close-snackbar",
                message: "Analisi chiusa con successo"
            }), {
                variant: "success",
            });

        } else {

            this.props.enqueueSnackbar(t({
                id: "as-error-close-snackbar",
                message: "Errore nella chiusura dell'analisi"
            }), {
                variant: "error",
            });
        }
    };

    private openMandateModal = () => {

        this.setState(() => ({isMandateModalOpen: true}));
    };

    private mandateModalCloseCallback = (mandateModalData: any) => {

        this.setState(() => ({isMandateModalOpen: false}));

        if (mandateModalData === null) {
            return;
        }

        if (mandateModalData.mandateType === MandateType.DOCUMENT) {
            MandateService.setDocumentMandate(this.state.analysis!.id, mandateModalData.document,
                this.documentMandateSuccess, this.documentMandateError);
            return;
        }
    };

    private documentMandateSuccess = () => {

        this.setState(() => ({showMandateButton: false}));

        this.props.enqueueSnackbar(t({
            id: "as-success-mandate-update-snackbar",
            message: "Mandato aggiornato"
        }), {
            variant: "success",
        });

        window.location.reload();
    };

    private documentMandateError = (error: any) => {

        this.props.enqueueSnackbar(t({
            id: "as-error-mandate-update-snackbar",
            message: "Errore nell'aggiornamento del mandato"
        }), {
            variant: "error",
        });
    };

    private updateTextCallback = (response: RestResponse<Analysis, ErrorResponse>) => {

        if (!response.hasError()) {
            this.props.enqueueSnackbar("Analisi aggiornata", {
                variant: "success",
            });
        } else {
            this.props.enqueueSnackbar(`Errore nell'aggiornamento dell'analisi`, {
                variant: "error",
            });
        }
    };

    private updateText = () => {

        let request = {
            text: this.state.selectedAnalysisText
        };

        AnalysesService.saveAnalysisText(this.state.analysis!.id, request, this.updateTextCallback);
    };

    private formatItalianDate = (value: string | null) => {

        if (value !== null) {
            return DateTime.fromMillis(Date.parse(value)).toFormat("dd/MM/yyyy");
        }
    };

    render() {
        let currentLanguage = window.localStorage.getItem("selected_language") || "IT";

        return (<>
            <Dialog
                open={this.state.isMandateModalOpen}>
                <MandateModal closeModal={this.mandateModalCloseCallback}/>
            </Dialog>
            <ConfirmDialog ok={this.confirmDeleteCallback}
                           open={this.state.confirmDeleteDialogShow}
                           message={t({
                               id: "as-delete-analysis-message",
                               message: "L'operazione di cancellazione dell'analisi è irreversibile. Procedere?"
                           })}
                           okText={t({
                               id: "as-delete-analysis-confirm",
                               message: "Conferma"
                           })}
                           cancelText={t({
                               id: "as-delete-analysis-cancel",
                               message: "Annulla"
                           })}
                           title={t({
                               id: "as-delete-analysis-modal-title",
                               message: "Conferma eliminazione analisi"
                           })}
                           cancel={() => {
                               this.setState(() => ({confirmDeleteDialogShow: false}));
                           }}/>
            <ConfirmDialog ok={this.confirmCloseCallback}
                           open={this.state.confirmClosedDialogShow}
                           message={t({
                               id: "as-close-analysis-message",
                               message: "L'operazione di chiusura dell'analisi è irreversibile. Procedere?"
                           })}
                           okText={t({
                               id: "as-close-analysis-confirm",
                               message: "Conferma"
                           })}
                           cancelText={t({
                               id: "as-close-analysis-cancel",
                               message: "Annulla"
                           })}
                           title={t({
                               id: "as-close-analysis-modal-title",
                               message: "Conferma chiusura analisi"
                           })}
                           cancel={() => {
                               this.setState(() => ({confirmClosedDialogShow: false}));
                           }}/>
            <Loading show={this.state.isLoading}/>
            {!this.state.isLoading && <>
                {this.state.analysis !== null &&
                    <NavigationBack backRoute={`/analyses/${this.state.analysis!.customer!.id}`}/>}
                <Typography variant="h3" marginBottom={2} textAlign={"center"}>
                    <Trans id="as-title">
                        Riepilogo analisi
                    </Trans>
                </Typography>
                <Box component="div" className="onboard-container summary-container">
                    <Grid container spacing={4} sx={{mt: 2}}>
                        <Grid item xs={12}>
                            <AnalysisSummaryFields analysis={this.state.analysis}/>
                        </Grid>
                        <Grid item xs={6}>
                            <RiskFactorTable riskFactor={this.state.analysis!.riskValue}/>
                        </Grid>
                        <Grid item xs={6} textAlign="right">
                            <TextField multiline
                                       rows={4}
                                       variant="outlined"
                                       value={this.state.selectedAnalysisText}
                                       onChange={val => this.setState(() => ({selectedAnalysisText: val.target.value}))}
                                       fullWidth={true}
                                       label={t({
                                           id: "as-analysis-text-label",
                                           message: "Analisi"
                                       })}
                                       disabled={this.state.isClosed}/>
                            {!this.state.isClosed && <Button
                                color="primary"
                                sx={{mt: 1}}
                                onClick={this.updateText}>
                                <Trans id={"as-analysis-text-save"}>
                                    Salva
                                </Trans>
                            </Button>}
                            {this.state.analysis?.mandate! &&
                                <Grid container>
                                    <Grid item xs={9}>
                                        <Typography
                                            variant={"body1"}
                                            textAlign={"left"}
                                            sx={{p: 2}}>
                                            <Trans id={"as-analysis-mandate-date"}>
                                                Mandato caricato correttamente il
                                            </Trans> {this.formatItalianDate(this.state.analysis!.mandate.uploadDate)}
                                            <br/>{this.state.analysis!.mandate.fileName}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button
                                            color="primary"
                                            sx={{mt: 4}}
                                            onClick={() => window.location.href = `/api/analyses/${this.state.analysis?.id}/download-mandate`}>
                                            <Trans id={"as-analysis-mandate-download"}>
                                                Scarica
                                            </Trans>
                                        </Button>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                        <Grid item xs={12} spacing={1}>
                            <Box textAlign={"right"}>
                                {!this.state.isClosed && <Button
                                    startIcon={<DeleteIcon/>}
                                    color="error"
                                    variant="outlined"
                                    sx={{mr: 1}}
                                    onClick={this.confirmDeleteOpen}>
                                    <Trans id={"as-delete"}>
                                        Elimina
                                    </Trans>
                                </Button>}
                                {!this.state.isClosed && ((this.state.user?.role === UserRole.WORKER && this.state.analysis?.customer?.worker === this.state.user) || (this.state.user?.role !== UserRole.SECRETARY)) &&
                                    <Button
                                        sx={{mr: 1}}
                                        color="primary"
                                        variant="outlined"
                                        onClick={this.confirmCloseOpen}>
                                        <Trans id={"as-close"}>
                                            Chiudi analisi
                                        </Trans>
                                    </Button>}
                                {(!this.state.isClosed && ((this.state.analysis?.mandate === null) || this.state.showMandateButton)) &&
                                    <Button
                                        color="primary"
                                        sx={{mr: 1}}
                                        variant="contained"
                                        onClick={this.openMandateModal}>
                                        <Trans id={"as-insert-mandate"}>
                                            Inserisci mandato
                                        </Trans>
                                    </Button>}
                                {(this.state.analysis?.report !== null) && <Button
                                    color="secondary"
                                    sx={{mr: 1}}
                                    variant="contained"
                                    onClick={() => window.location.href = `/api/analyses/${this.state.analysis!.id}/download-report`}>
                                    <Trans id={"as-delete-last-report"}>
                                        Scarica ultimo Report
                                    </Trans>
                                </Button>}
                                {!this.state.isClosed && <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => window.location.href = `/api/analyses/${this.state.analysis!.id}/generate-report?lang=${currentLanguage}`}>
                                    <Trans id={"as-generate-report"}>
                                        Genera Report
                                    </Trans>
                                </Button>}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </>}
        </>);
    }
}

const componentWithRouter = withRouter(AnalysisSummary);
export default withSnackbar(componentWithRouter);
