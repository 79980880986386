import React from 'react'

import {
	Box,
	Button,
	Typography,
} from "@mui/material"

import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied"

import { Link } from "react-router-dom"
import { Trans } from "@lingui/macro"

export class PageNotFound extends React.Component<{}, {}>
{
	render() {
		return (
			<Box
				sx={{
					marginTop: 8,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<SentimentVeryDissatisfiedIcon color="error" sx={{ fontSize: 120 }} />
				<Typography mt={2} mb={5} variant="h3">
					<Trans id="errors.404.message" comment="404 Not found page message">Questa pagina non esiste</Trans>
				</Typography>

				<Link to="/">
					<Button variant="contained" startIcon={<ArrowBackIcon />}>
						<Trans id="buttons.back" comment="Back text on buttons">Indietro</Trans>
					</Button>
				</Link>
			</Box>
		)
	}
}
