import React from "react";

import {
	UsersTableProps,
	UsersTableState
} from "./models";
import {MainConstants, User, UserRole} from "../../../pages/models";
import {
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Trans } from "@lingui/macro";
import { DateTime } from "luxon";
import ChangeUserModal from "../change-type-modal/change-user-modal";


export class UsersTable extends React.Component<UsersTableProps, UsersTableState>
{

	private currentUser: string | null
	private currentLanguage : any

	constructor(props :UsersTableProps ) {
		super(props);

		this.state = {
			selectedRow: "",
		}

		let session: any = sessionStorage.getItem("currentUser")
		this.currentUser = JSON.parse(session)!.id
		this.currentLanguage = window.localStorage.getItem("selected_language") || "IT";


	}

	private formatItalianDate = (value: string | null) => {

		if(value !== null) {
			return DateTime.fromMillis(Date.parse(value)).toFormat("dd/MM/yyyy HH:mm");
		}

	};

	private openModal = (row : User) =>{
		this.setState(() => ({
			selectedRow: row.id
		}))
	}

	private handleClose = () => {
		this.setState(() => ({
			selectedRow: ""
		}))
	}

	editUser = (id: string, role : UserRole): void => {
		this.props.editUser(id, role)
		this.setState(() => ({
			selectedRow: ""
		}))
	}

	private mapRole = (role : UserRole): string =>{
		let entry = MainConstants.USER_ROLE_LIST.find(element => element.key === role)

		console.log(entry)
		return this.currentLanguage === "IT"? entry!.label : entry!.labelEn
	}
	render()
	{
		return (<>

			<ChangeUserModal isOpen={this.state.selectedRow!==""}
							 handleClose={this.handleClose}
							 handleSubmitCallback={this.editUser}
							 id={this.state.selectedRow}/>
			<TableContainer>
				<Table sx={{minWidth: 650}} aria-label="simple table">
					<TableHead>
						<TableRow sx={{backgroundColor:"lightgray"}}>
							<TableCell>
								<Trans id={"ut-email"}>
									Indirizzo e-mail
								</Trans>
							</TableCell>
							<TableCell>
								<Trans id={"ut-name"}>
									Nome
								</Trans>
							</TableCell>
							<TableCell>
								<Trans id={"ut-surname"}>
									Cognome
								</Trans>
							</TableCell>
							<TableCell>
								<Trans id={"ut-role"}>
									Ruolo
								</Trans>
							</TableCell>
							<TableCell>
								<Trans id={"ut-creation-date"}>
									Data creazione
								</Trans>
							</TableCell>
							<TableCell>
								<Trans id={"ut-actions"}>
									Azioni
								</Trans>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{this.props.usersList?.map((row) => (<TableRow
							key={row.id}>
							<TableCell component="th" scope="row">
								{row.email}
							</TableCell>
							<TableCell>{row.name}</TableCell>
							<TableCell>{row.surname}</TableCell>
							<TableCell>{this.mapRole(row.role)}</TableCell>
							<TableCell>{this.formatItalianDate(row.creationDate)}</TableCell>
							<TableCell>
								{row.id!==this.currentUser && <><Button onClick={() => this.props.deleteUser(row)}>
									<DeleteIcon/>
								</Button>
								<Button onClick={() => this.openModal(row)}>
									<EditIcon/>
								</Button>
								</>}
							</TableCell>
						</TableRow>))}
					</TableBody>
				</Table>
			</TableContainer>
		</>);
	}
}
