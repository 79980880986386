import React from "react";

import {
	InfoDialogProps,
	InfoDialogState
} from "./models";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle
} from "@mui/material";
import { Trans } from "@lingui/macro";

export default class InfoDialog extends React.Component<InfoDialogProps, InfoDialogState>
{

	render()
	{
		return (<Dialog open={true}>
			<DialogTitle color={"primary"}>
				{this.props.title}
			</DialogTitle>
			<DialogContent>
				{this.props.simpleText !== null && <DialogContentText>
					{this.props.simpleText}
				</DialogContentText>}
			</DialogContent>
			<DialogActions>
				<Button onClick={this.props.closingButtonAction}>
					<Trans id="id-ok"> OK</Trans>
				</Button>
			</DialogActions>
		</Dialog>);
	}
}
