import React from "react";
import {
	OfficeSetupProps,
	OfficeSetupState
} from "./models";
import { withRouter } from "react-router-dom";
import { User } from "../models";

import {
	Box,
	Typography
} from "@mui/material";
import { Trans } from "@lingui/macro";
import "./office-setup.css";
import { SessionService } from "../../services/session.service";
import OfficeSetupForm from "../../components/office-setup/office-setup-form/office-setup-form";
import { Loading } from "@sinossi/mates-react-library";

class OfficeSetup extends React.Component<OfficeSetupProps, OfficeSetupState>
{
	constructor(props: any)
	{
		super(props);

		this.state = {
			currentUser: null,
			loading:true
		};

		SessionService.getCurrentUser(this.getUserSuccess, this.getUserFailure);
	}

	private getUserFailure = (errorResponse: any) => {
		console.log(errorResponse);
	};

	private getUserSuccess = () => {

		let currentUserString = window.sessionStorage.getItem("currentUser");
		let currentUser: any = null;

		if(currentUserString != null) {
			let currentUser: User = JSON.parse(currentUserString);
			if(currentUser.office !== null) {
				if(currentUser.office.active) {
					this.props.history.push("/customers");
				}
				return;
			}
		}

		this.setState(() => ({
			currentUser: currentUser,
			loading: false
		}));
	};

	render()
	{
		return (<>
			<Loading show={this.state.loading} />
			{!this.state.loading && <Box component="div" className="main-container">
				<Typography variant="h3">
					<Trans id="office-setup.header">Benvenuto</Trans>
				</Typography>
				<Typography variant="body1" sx={{mt: 1}}>
					<Trans id="office-setup.subtitle">Per utilizzare tutte le funzionalità di JMT Antiriciclaggio devi
						prima inserire le informazioni del tuo Studio</Trans>
				</Typography>
				<Box component="div" className="info-container" sx={{mt: 2}}>
					<OfficeSetupForm />
				</Box>
			</Box>}</>);
	}
}

export default withRouter(OfficeSetup);