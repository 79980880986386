import {
	PreCalculateRiskRequest,
	PreCalculateRiskResponse
} from "./models";
import { RestService } from "@sinossi/mates-react-library";
import {Analysis, Operation} from "../../pages/models";

export class OperationService
{

	static preCalculateRisk = (request: PreCalculateRiskRequest, callback: any) => {

		RestService
			.post<PreCalculateRiskResponse>("/api/analyses/pre-calculate-risk", request)
			.then(callback);

	};

	static getOfficeWorkers = (callback: any) => {

		RestService
			.get<PreCalculateRiskResponse>("/api/offices/workers")
			.then(callback);

	};

	static getOperation = (id : string | null, callback: any) => {

		RestService.get<Analysis>(`/api/operations/${id}`)
			.then(callback);
	};

	static saveOperation = (request : Operation, callback: any) =>{
		RestService.post<Operation>(`/api/operations`, request).then(callback);
	}

	static updateOperation = (request : Operation, callback: any) =>{
		RestService.put<Operation>(`/api/operations`, request).then(callback);
	}
}