import React from "react";
import {
    LoginBoxProps,
    LoginBoxState
} from "./models";

import {
    Box,
    Button,
    Stack,
    Typography
} from "@mui/material";
import {Trans} from "@lingui/macro";

import RegistrationForm from "../registration-form/registration-form";

import "./login-box.css";

export default class LoginBox extends React.Component<LoginBoxProps, LoginBoxState> {
    constructor(props: any) {
        super(props);

        this.state = {
            showLogin: true
        };
    }

    private submitForm = (values: any) => {

        let keycloak = this.props.keycloak;

        keycloak.login({
            redirectUri: process.env.REACT_APP_BASE_URL + "/customers"
        });
    };

    private dontShowLogin = () => {
        this.setState(() => ({showLogin: !this.state.showLogin}));
    };

    render() {

        const DELIVERY_MODE = process.env.REACT_APP_DELIVERY_MODE as string;

        return (<>
            {this.state.showLogin && <Box
                className="loginContainer">
                <Stack spacing={2}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={this.submitForm}>
                        <Trans id={"lb-login"}>
                            Accedi
                        </Trans>
                    </Button>
                    {DELIVERY_MODE === "AS_A_SERVICE" && <>
                        <Typography variant="body2" textAlign={"center"}>
                            <Trans id="lb-or">
                                oppure
                            </Trans>
                        </Typography>
                        <Button
                            sx={{mt: 1}}
                            color="secondary"
                            onClick={this.dontShowLogin}>
                            <Trans id={"lb-register"}>
                                Registrati
                            </Trans>
                        </Button>
                    </>}
                </Stack>
            </Box>}
            {!this.state.showLogin && <>
                <RegistrationForm keycloak={this.props.keycloak}/>
            </>}
        </>);
    }
}
