import React from "react";

import {
	Box,
	Step,
	StepLabel,
	Stepper,
	Typography
} from "@mui/material";

import { Trans } from "@lingui/macro";
import {
	Analysis,
	Customer,
	Operation
} from "../models";

import "../style.css";
import { CustomersService } from "../../services/customers.service";
import {
	ErrorResponse,
	Loading,
	RestErrorDialog,
	RestResponse
} from "@sinossi/mates-react-library";
import { ErrorService } from "../../services/error.service";
import OperationForm from "../../components/operations/operation-form/operation-form";
import {
	CreateAnalysisRequest,
	CreateOperationProps,
	CreateOperationState
} from "./model";
import { CreateOperationRiskRecap } from "../../components/operations/create-operation-risk-recap/create-operation-risk-recap";
import NavigationBack from "../../components/navigation/navigation-back/navigation-back";
import { AnalysesService } from "../../services/analyses.service";

export class CreateOperation extends React.Component<CreateOperationProps, CreateOperationState>
{

	constructor(props: any) {
		super(props);

		this.state = {
			errorConfig: {
				options: { showErrorTimestamp: true },
				showErrorCode: true
			},
			loading: true,
			openError: false,
			response: null,
			activeStep: 0,
			customer: null,
			operation: null,
			creationSuccess: false
		};
	}

	getCustomerCallback = (response: RestResponse<Customer, ErrorResponse>) => {
		if (response.hasError()) {
			this.setState(() => ({
				openError: true,
				loading: false
			}));
		} else {
			this.setState(() => ({
				creationSuccess: true,
				customer: response.data,
				loading: false
			}));
		}
	};

	componentDidMount() {
		let customerId = this.props.match.params.customerId;

		CustomersService.getCustomer(customerId, this.getCustomerCallback);
	}

	goBackInStep = () => {
		this.setState(() => ({ activeStep: this.state.activeStep - 1 }));
	};

	closeErrorModal = () => {
		this.setState(() => ({
			openError: false
		}));
	};

	getOperationData = (operation: Operation) => {
		this.setState(() => ({
			operation: operation,
			activeStep: 1
		}));
	};

	createAnalysisCallback = (response: RestResponse<Analysis, ErrorResponse>) => {
		if (response.hasError()) {
			//TODO add error handling
		} else {
			this.props.history.push(`/customers/${this.props.match.params.customerId}/create-operation/success`);
		}
	};

	onOperationCreate = (selectedValues: any) => {

		let newCustomer = Object.assign({}, this.state.customer);
		newCustomer.additionalInfo.requiredDocuments = selectedValues.requiredDocuments;

		this.setState(() => ({
			customer: newCustomer
		}));

		let request: CreateAnalysisRequest = {
			customer: this.state.customer,
			operation: this.state.operation,
			selectedVerificationType: selectedValues.selectedADV,
			selectedAdvDuration: selectedValues.selectedADVExpiry
		};

		AnalysesService.createAnalysis(request, this.createAnalysisCallback);
	};

	private getCurrentStepContent = () => {
		switch (this.state.activeStep) {
			case 0:
				return (<OperationForm operation={null}
					onOperationSubmit={this.getOperationData}
					isCreateAnalysis={true}
					back={this.goBackInStep} />);
			case 1:
				return (<CreateOperationRiskRecap customer={this.state.customer}
					operation={this.state.operation}
					back={this.goBackInStep} onCreation={this.onOperationCreate} />);
		}
	};

	render() {
		return (<Box component="div">
			<NavigationBack backRoute={`/analyses/${this.state.customer?.id}`} />
			<RestErrorDialog
				open={this.state.openError}
				response={this.state.response}
				ok={this.closeErrorModal}
				errorParser={ErrorService.parseErrorCode}
				{...this.state.errorConfig} />
			<Loading show={this.state.loading} />
			<Typography variant="h3" className={"text-center"}>
				<Trans id="cro-header">
					Crea analisi prestazione
				</Trans>
			</Typography>
			<Typography variant="h5" className={"text-center"} sx={{ mt: 2 }}>
				{this.state.customer?.denomination}
			</Typography>
			<Box sx={{
				backgroundColor: "#FAFBFB",
				p: 2,
				mt: 2
			}} className="onboard-container">
				<Stepper activeStep={this.state.activeStep}>
					<Step>
						<StepLabel>
							<Trans id={"cro-op-data"}>
								Dati Operazione
							</Trans>
						</StepLabel>
					</Step>
					<Step>
						<StepLabel>
							<Trans id={"cro-summary-data"}>
								Riepilogo
							</Trans>
						</StepLabel>
					</Step>
				</Stepper>
				<Box component="div" sx={{ mt: 2 }}>
					{this.getCurrentStepContent()}
				</Box>
			</Box>
		</Box>);
	}
}