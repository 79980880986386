import React from "react";

import {
	CustomerOnboardProps,
	CustomerOnboardState

} from "./models";

import {
	Box,
	Step,
	StepLabel,
	Stepper,
	Typography
} from "@mui/material";

import "./customer-onboard.css";
import {
	t,
	Trans
} from "@lingui/macro";
import {
	Customer,
	Operation
} from "../models";

import "../style.css";

import { CustomersService } from "../../services/customers.service";

import {
	ErrorResponse,
	Loading,
	RestErrorDialog,
	RestResponse
} from "@sinossi/mates-react-library";

import { ErrorService } from "../../services/error.service";
import { Redirect } from "react-router-dom";

import CustomerForm from "../../components/customers/customer-form/customer-form";
import OperationForm from "../../components/operations/operation-form/operation-form";


import NavigationBack from "../../components/navigation/navigation-back/navigation-back";
import RiskRecap from "../../components/operations/risk-recap/risk-recap";
import {
	withSnackbar,
	WithSnackbarProps
} from "notistack";

class CustomerOnboard extends React.Component<CustomerOnboardProps & WithSnackbarProps, CustomerOnboardState>
{

	constructor(props: any)
	{
		super(props);

		this.state = {
			onboardingSuccess: false,
			errorConfig: {
				options: {showErrorTimestamp: true},
				showErrorCode: true
			},
			loading: false,
			openError: false,
			response: null,
			activeStep: 0,
			customer: null,
			operation: null,
            onboardingError: false
		};
	}

	goBackInStep = () => {
		this.setState(() => ({activeStep: this.state.activeStep - 1}));
	};

	getCustomerData = (customer: Customer) => {

		this.setState(() => ({
			customer: customer,
			activeStep: 1
		}));
	};

	getOperationData = (operation: Operation) => {

		this.setState(() => ({
			operation: operation,
			activeStep: 2
		}));
	};

	startOnboarding = (selectedValues: any) => {

		this.setState(() => ({loading: true}));

		let newCustomer = Object.assign({}, this.state.customer);

		newCustomer.verificationType = selectedValues.selectedADV;
		newCustomer.additionalInfo.advDuration = selectedValues.selectedADVExpiry;
		newCustomer.worker = {id: selectedValues.selectedWorker};
		newCustomer.additionalInfo.requiredDocuments = selectedValues.requiredDocuments;

		let onBoardingRequest = {
			customer: newCustomer,
			operation: this.state.operation,
			formLanguage: selectedValues.formLanguage
		};

		console.log(onBoardingRequest);

		CustomersService.onBoard(onBoardingRequest, this.onBoardingCallback);
	};

	private onBoardingCallback = (response: RestResponse<Customer, ErrorResponse>) => {

		if(response.hasError()) {
			this.setState(() => ({
				loading: false,
                onboardingError: true
			}));

			this.props.enqueueSnackbar(t({
				id: "co-error-start-onboard-snackbar",
				message: "Si è verificato un errore nello svolgimento dell'Adeguata Verifica"
			}), {
				variant: "error",
			});

		} else {
			this.setState(() => ({
				onboardingSuccess: true,
                onboardingError: false
			}));
		}

	};

	closeErrorModal = () => {
		this.setState(() => ({openError: false}));
	};

	private getCurrentStepContent = () => {
		switch(this.state.activeStep) {
			case 0:
				return (<CustomerForm customer={this.state.customer} onCustomerSubmit={this.getCustomerData} />);
			case 1:
				return (<OperationForm operation={null}
					onOperationSubmit={this.getOperationData}
					back={this.goBackInStep} />);
			case 2:
				return (<RiskRecap customer={this.state.customer}
					operation={this.state.operation}
					back={this.goBackInStep} onOnboardingStart={this.startOnboarding}
                    onboardingError={this.state.onboardingError}
                />);
		}
	};

	render()
	{
		return (<Box component="div">
			{this.state.onboardingSuccess && <Redirect to={"onboard/success"} />}
			<NavigationBack backRoute={"/customers"} />
			<RestErrorDialog
				open={this.state.openError}
				response={this.state.response}
				ok={this.closeErrorModal}
				errorParser={ErrorService.parseErrorCode}
				{...this.state.errorConfig} />
			<Loading show={this.state.loading} />
			<Typography variant="h3" className={"text-center"}>
				<Trans id="customers-onboard.header">Nuovo cliente</Trans>
			</Typography>
			<Box sx={{
				p: 2,
				mt: 2
			}} className="onboard-container">
				<Stepper activeStep={this.state.activeStep}>
					<Step key={0}>
						<StepLabel>
							<Trans id={"con-customer-data"}>
								Dati Cliente
							</Trans>
						</StepLabel>
					</Step>
					<Step>
						<StepLabel>
							<Trans id={"con-operation-data"}>
								Dati Operazione
							</Trans>
						</StepLabel>
					</Step>
					<Step>
						<StepLabel>
							<Trans id={"con-operation-summary"}>
								Riepilogo
							</Trans>
						</StepLabel>
					</Step>
				</Stepper>
				<Box component="div" sx={{mt: 2}}>
					{this.getCurrentStepContent()}
				</Box>
			</Box>
		</Box>);
	}
}

export default withSnackbar(CustomerOnboard);
