import React from "react";
import {
	OfficeSetupFormProps,
	OfficeSetupFormState
} from "./models";
import { withRouter } from "react-router-dom";
import {
	Box,
	Button,
	Grid,
	Typography
} from "@mui/material";
import "./office-setup-form.css";
import { ErrorService } from "../../../services/error.service";
import {
	ErrorResponse,
	RestErrorDialog,
	RestResponse
} from "@sinossi/mates-react-library";
import InfoIcon from "@mui/icons-material/Info";

import {
	Field,
	Form,
	Formik
} from "formik";
import { TextField } from "formik-mui";
import { OfficeSetupService } from "../../../services/office-setup.service";

class OfficeSetupForm extends React.Component<OfficeSetupFormProps, OfficeSetupFormState>
{
	constructor(props: any)
	{

		super(props);
		this.state = {
			denomination: "",
			response: null,
			openError: false,
			errorConfig: {
				showErrorCode: true,
				options: {
					showErrorTimestamp: true
				}
			}
		};
	}

	private validateForm = (values: any) => {

	};

	private submitForm = (values: any) => {

		let office = {
			address: undefined,
			email: values.email,
			fiscalCode: values.fiscalCode,
			id: "",
			name: values.denomination,
			pec: values.pec,
			type: undefined,
			vatCode: values.vatCode
		};

		OfficeSetupService.sendOfficeSetupRequest(office, this.officeActivationCallback);
	};

	private officeActivationCallback = (response: RestResponse<any, ErrorResponse>) => {

		if(!response.hasError()) {
			window.location.href = response.data.paymentRedirectUrl;
		} else {
		}

	};

	closeErrorModal = () => {
		this.setState({openError: false});
	};

	render()
	{
		return (

			<>
				<Box sx=
					{{
						backgroundColor: "#FAFBFB",
						p: 2
					}} className="form-container">
					<RestErrorDialog
						open={this.state.openError}
						response={this.state.response}
						ok={this.closeErrorModal}
						errorParser={ErrorService.parseErrorCode}
						{...this.state.errorConfig} />
					<Formik initialValues={{
						denomination: ""
					}} validate={this.validateForm} onSubmit={this.submitForm}>
						{({
							isSubmitting,
							submitForm
						}) => (<Form>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Field
										component={TextField}
										name="denomination"
										type="text"
										label="Denominazione"
										fullWidth
									/>
								</Grid>
								<Grid item xs={6}>
									<Field
										component={TextField}
										name="fiscalCode"
										type="text"
										label="Codice Fiscale"
										fullWidth
									/>
								</Grid>
								<Grid item xs={6}>
									<Field
										component={TextField}
										name="vatCode"
										type="text"
										label="Partita IVA"
										fullWidth
									/>
								</Grid>
								<Grid item xs={12}>
									<Typography variant="body1">
										Sede Legale
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Field
										component={TextField}
										name="address"
										type="text"
										label="Indirizzo"
										fullWidth
									/>
								</Grid>
								<Grid item xs={3}>
									<Field
										component={TextField}
										name="city"
										type="text"
										label="Città"
										fullWidth
									/>
								</Grid>
								<Grid item xs={1}>
									<Field
										component={TextField}
										name="zipCode"
										type="text"
										label="CAP"
										fullWidth
									/>
								</Grid>
								<Grid item xs={2}>
									<Field
										component={TextField}
										name="province"
										type="text"
										label="Provincia"
										fullWidth
									/>
								</Grid>
								<Grid item xs={3}>
									<Field
										component={TextField}
										name="nation"
										type="text"
										label="Nazione"
										fullWidth
									/>
								</Grid>
								<Grid item xs={12}>
									<Typography variant="body1">
										Contatti
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Field
										component={TextField}
										name="email"
										type="text"
										label="E-Mail"
										fullWidth
									/>
								</Grid>
								<Grid item xs={6}>
									<Field
										component={TextField}
										name="pec"
										type="text"
										label="PEC"
										fullWidth
									/>
								</Grid>
								<Grid item xs={12} className="left-align">
									<Typography variant="body1">
										<InfoIcon /> Cliccando su procedi verrai reindirizzato al gestore di pagamenti
										per
										procedere con
										il pagamento sicuro
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Button
										color="primary"
										variant="contained"
										/*disabled={isSubmitting}*/
										onClick={submitForm}>
										Procedi
									</Button>
								</Grid>
							</Grid>
						</Form>)}
					</Formik>
				</Box>
			</>);
	}
}

export default withRouter(OfficeSetupForm);