import React from "react";
import {
	PaymentSuccessProps,
	PaymentSuccessState
} from "./models";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
	Box,
	Button,
	Typography
} from "@mui/material";
import { Trans } from "@lingui/macro";
import { Link } from "react-router-dom";

export class PaymentSuccess extends React.Component<PaymentSuccessProps, PaymentSuccessState> {

	render() {
		return(<Box
			sx={{
				marginTop: 8,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
			}}
		>
			<CheckCircleIcon color="success" sx={{fontSize: 120}} />
			<Typography mt={2} mb={2} variant="h3" textAlign={"center"}>
				<Trans id="success-payment.message-title"> Attivazione studio completata </Trans>
			</Typography>
			<Typography variant="h5" mb={5} textAlign={"center"}>
				<Trans id="success-payment.message"> Ora puoi utilizzare tutte le funzionalità di JMT Antiriciclaggio</Trans>
			</Typography>

			<Link to="/customers">
				<Button variant="contained">
					<Trans id="buttons.go-to-customers" > Entra </Trans>
				</Button>
			</Link>
		</Box>);
	}
}