import {
	CreateOperationRiskRecapProps,
	CreateOperationRiskRecapState,
} from "./models";
import React from "react";
import {
	Box,
	Button,
	Checkbox,
	Chip,
	CircularProgress,
	FormControlLabel,
	FormGroup,
	Grid,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography
} from "@mui/material";
import {
	t,
	Trans
} from "@lingui/macro";
import {
	MainConstants,
	VerificationDuration,
	VerificationType
} from "../../../pages/models";
import {
	PreCalculateRiskRequest,
	PreCalculateRiskResponse
} from "../../../services/operation/models";
import {
	ErrorResponse,
	RestResponse
} from "@sinossi/mates-react-library";
import ErrorIcon from "@mui/icons-material/Error";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { OperationService } from "../../../services/operation/operation.service";
import { CustomDocumentToRenderModel } from "../risk-recap/models";

export class CreateOperationRiskRecap extends React.Component<CreateOperationRiskRecapProps, CreateOperationRiskRecapState>
{

	private submitForm = () => {

		let selectedValues = {
			selectedADV: this.state.selectedADV,
			selectedADVExpiry: this.state.selectedADVExpiry,
			requiredDocuments: this.state.requiredDocumentsList.concat(this.state.customDocumentsList.map((el) => {
				return {
					name: el.name,
					type: el.type
				};
			}))
		};

		this.props.onCreation(selectedValues);
	};

	constructor(props: any)
	{
		super(props);

		this.state = {
			onError: false,
			selectedADV: null,
			selectedADVExpiry: null,
			isLoading: true,
			riskValue: {
				customerValue: 0,
				operationValue: 0,
				total: 0,
				suggestedVerificationType: VerificationType.SIMPLIFIED,
				suggestedAdvDuration: VerificationDuration.THREE_YEARS,
				editableSuggestedData: true
			},
			customDocumentsNumber: 0,
			customDocumentsList: [],
			requiredDocumentsList: [],
			customDocumentsToRender: []
		};

	}

	componentDidMount()
	{
		let preCalculateRiskRequest: PreCalculateRiskRequest = {
			customer: this.props.customer,
			operation: this.props.operation
		};

		OperationService.preCalculateRisk(preCalculateRiskRequest, this.getRiskCallback);
	}

	private getRiskCallback = (response: RestResponse<PreCalculateRiskResponse, ErrorResponse>) => {

		if(!response.hasError()) {

			this.setState(() => ({
				riskValue: response.data != null ? response.data : {
					customerValue: 0,
					operationValue: 0,
					total: 0,
					suggestedVerificationType: VerificationType.SIMPLIFIED,
					suggestedAdvDuration: VerificationDuration.THREE_YEARS,
					editableSuggestedData: true
				},
				isLoading: false
			}));

			this.setState(() => ({
				selectedADV: this.state.riskValue.suggestedVerificationType,
				selectedADVExpiry: this.state.riskValue.suggestedAdvDuration
			}));

		} else {
			this.handleRestCallError();
		}
	};

	private handleRestCallError()
	{
		this.setState(() => ({
			onError: true,
			isLoading: false
		}));
	}

	private backForm = () => {
		this.props.back();
	};

	private setTotalBgColor: any = () => {

		// @ts-ignore: Object is possibly 'null'.
		if(this.state.riskValue!.total <= 20) {
			return "primary";
		}
		// @ts-ignore: Object is possibly 'null'.
		if(this.state.riskValue.total > 20 && this.state.riskValue.total <= 30) {
			return "success";
		}
		// @ts-ignore: Object is possibly 'null'.
		if(this.state.riskValue.total > 30 && this.state.riskValue.total <= 40) {
			return "warning";
		}
		// @ts-ignore: Object is possibly 'null'.
		if(this.state.riskValue.total > 40) {
			return "error";
		}

		return "primary";
	};


	private handleDocChange = (e: any) => {

		if(e.target.checked) {

			let requiredDocuments: any[] = this.state.requiredDocumentsList;

			let doc = {
				type: e.target.value,
				name: null
			};

			requiredDocuments.push(doc);

			this.setState(() => ({
				requiredDocumentsList: requiredDocuments
			}));

		} else {

			let requiredDocuments: any[] = [];

			for(let docEl of this.state.requiredDocumentsList) {
				if(docEl.type !== e.target.value) {
					requiredDocuments.push(docEl);
				}
			}

			this.setState(() => ({
				requiredDocumentsList: requiredDocuments
			}));
		}

		console.log(this.state.requiredDocumentsList);
	};

	/*
	 *	FIXME: This method needs refactoring!
	 */
	private addOtherDocument = () => {

		let id = "doc" + Math.random() * 100;

		let customDocuments: any[] = this.state.customDocumentsList;

		let doc = {
			type: "OTHER",
			name: "",
			id: id
		};

		customDocuments.push(doc);

		let rows = this.state.customDocumentsToRender;

		for(let i = 0; i < customDocuments.length - rows.length; i++) {

			let rowElement: CustomDocumentToRenderModel = {
				id: null,
				node: null
			};

			rowElement.id = id;
			rowElement.node = <Grid key={id} id={id} item xs={6} sx={{mt: 1}}>
				<Stack direction={"row"}
					spacing={2}
					style={{
						display: "inline-flex",
						alignItems: "center",
						width: "100%"
					}}>
					<TextField
						name="name"
						label={t({
							id: "rr-document-name",
							message: "Nome del documento"
						})}
						size={"small"}
						onChange={(e: any) => {

							let newCustomDocuments: any[] = this.state.customDocumentsList;

							let doc = newCustomDocuments.find(function(element) {
								return element.id === id;
							});

							let index = newCustomDocuments.indexOf(doc);

							doc.name = e.target.value;

							newCustomDocuments[index] = doc;

							this.setState(() => ({
								customDocumentsList: newCustomDocuments
							}));
						}} />
					<RemoveCircleOutlineIcon
						sx={{cursor: "pointer"}}
						onClick={() => {
							this.removeCustomDocument(id);
						}} />
				</Stack>
			</Grid>;

			rows.push(rowElement);
		}

		this.setState(() => ({
			customDocumentsToRender: rows,
			customDocumentsList: customDocuments
		}));
	};

	/*
	 *	FIXME: THis method needs refactoring!
	 */
	private removeCustomDocument = (idToRemove: string) => {

		let customDocumentsToRender: CustomDocumentToRenderModel[] = this.state.customDocumentsToRender;
		let newCustomDocumentsToRender: CustomDocumentToRenderModel[] = [];

		for(let customDocument of customDocumentsToRender) {
			if(customDocument.id !== idToRemove) {
				newCustomDocumentsToRender.push(customDocument);
			}
		}

		let customDocuments: any[] = this.state.customDocumentsList;
		let newCustomDocuments: any[] = [];

		for(let customDocument of customDocuments) {
			if(customDocument.id !== idToRemove) {
				newCustomDocuments.push(customDocument);
			}
		}

		this.setState(() => ({
			customDocumentsToRender: newCustomDocumentsToRender,
			customDocumentsList: newCustomDocuments
		}));

		console.log(newCustomDocuments);
	};

	render()
	{
		let currentLanguage = window.localStorage.getItem("selected_language") || "IT";

		return (<>
			{this.state.isLoading && <Box textAlign={"center"}> <CircularProgress sx={{mt: 5}} /> </Box>}
			{(!this.state.isLoading && this.state.onError) &&
				<Box marginTop={4} textAlign={"center"}><ErrorIcon color="error" sx={{fontSize: 45}} />
					<Typography variant="h5" color={"error"}>
						<Trans id="risk-recap.error"> Si è verificato un errore con il calcolo del rischio</Trans>
					</Typography>
					<Typography variant="body1" marginTop={2}>
						<Trans id="risk-recap.error.msg"> Verificare i dati inseriti e riprovare</Trans>
					</Typography>
					<Button
						color="error"
						variant="outlined"
						sx={{mt: 3}}
						onClick={this.backForm}>
						<Trans id={"corr-retry"}>
							Riprova
						</Trans>
					</Button>
				</Box>}
			{(!this.state.isLoading && !this.state.onError) && <Box sx={{
				mt: 3,
				p: 3
			}}>
				<Grid container>
					<Grid xs={4} textAlign={"center"}>
						<Stack direction="row" justifyContent="center" alignItems={"baseline"}>
							<Typography variant="body1">
								<Trans id="risk-recap.customer-risk-1"> Rischio riferito al cliente</Trans>
							</Typography>
							<Chip label={this.state.riskValue.customerValue + "/20"}
								variant="outlined"
								sx={{
									ml: 2,
									fontSize: "20px",
									p: 1
								}} />
						</Stack>
					</Grid>
					<Grid xs={5} textAlign={"center"}>
						<Stack direction="row" justifyContent="center" alignItems={"baseline"}>
							<Typography variant="body1">
								<Trans id="risk-recap.customer-risk-2">Rischio riferito all'operazione</Trans>
							</Typography>
							<Chip label={this.state.riskValue?.operationValue + "/30"}
								variant="outlined"
								sx={{
									ml: 2,
									fontSize: "20px",
									p: 1
								}} />
						</Stack>
					</Grid>
					<Grid xs={3} textAlign={"center"}>
						<Stack direction="row" justifyContent="center" alignItems={"baseline"}>
							<Typography variant="body1">
								<Trans id="risk-recap.customer-risk-3">Totale Rischio</Trans>
							</Typography>
							<Chip label={this.state.riskValue?.total + "/50"}
								color={this.setTotalBgColor()}
								sx={{
									ml: 2,
									fontSize: "20px",
									p: 1
								}} />
						</Stack>
					</Grid>
				</Grid>
				<Grid container spacing={2} sx={{mt: 3}}>
					<Grid xs={12} sx={{mt: 2}}>
						<Typography variant="body1">
							<Trans id="risk-recap.customer-risk-4">La metodologia di adeguata verifica consigliata
								è la seguente</Trans>
						</Typography>
					</Grid>
					<Grid xs={12} sx={{mt: 2}}>
						<Select fullWidth
							onChange={(e: any) => this.setState(() => ({
								selectedADV: e.target.value
							}))}
							value={this.state.selectedADV}
							disabled={!this.state.riskValue.editableSuggestedData}>
							{MainConstants.ADV_TYPE_LIST.map(
								(item) => (<MenuItem value={item.key}>{currentLanguage === "IT"? item.label : item.labelEn}</MenuItem>))}
						</Select>
					</Grid>
					<Grid xs={12} sx={{mt: 2}}>
						<Typography variant="body1">
							<Trans id="risk-recap.customer-risk">La durata di validità dell'adeguata verifica suggerita
								è la seguente </Trans>
						</Typography>
					</Grid>
					<Grid xs={12} sx={{mt: 2}}>
						<Select fullWidth
							defaultValue={this.state.selectedADVExpiry}
							onChange={(e: any) => this.setState(() => ({
								selectedADVExpiry: e.target.value
							}))}
							disabled={!this.state.riskValue.editableSuggestedData}>
							{MainConstants.ADV_EXPIRY_LIST.map(
								(item) => (<MenuItem value={item.key}>{currentLanguage === "IT"? item.label : item.labelEn}</MenuItem>))}
						</Select>
					</Grid>
					<Grid item xs={12} sx={{mt: 2}}>
						<Typography variant="body1">
							<Trans id="risk-recap.document"> Documenti da richiedere </Trans>
						</Typography>
					</Grid>
					<Grid item xs={6} sx={{mt: 2}}>
						{MainConstants.DOCUMENT_TYPE_LIST.map((item, index) => (<FormGroup key={item.key}>
							<FormControlLabel control={<Checkbox
								value={item.key}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTimeout(
									() => this.handleDocChange(e), 0)} />}
								label={currentLanguage === "IT"? item.label : item.labelEn} />
						</FormGroup>))}
					</Grid>
					<Grid item xs={6} sx={{mt: 2}}>
						<Button
							color="primary"
							variant="contained"
							size={"small"}
							sx={{mb: 1}}
							onClick={this.addOtherDocument}>
							<Trans id={"rr-add-document"}>
								Aggiungi documento da richiedere
							</Trans>
						</Button>
						{this.state.customDocumentsToRender.map(el => el.node)}
					</Grid>
					<Grid item xs={12}>
						{!this.state.riskValue.editableSuggestedData && <Typography variant={"body1"} color={"error"}>
							<ErrorIcon sx={{mr: 2}} />
							<Trans id={"cor-reinforced-mandatory"}>
								Sono presenti delle condizioni per cui è fatto obbligo di legge procedere con una
								verifica RAFFORZATA
							</Trans>
						</Typography>}
					</Grid>
					<Grid item xs={12}>
						{(this.state.selectedADV !== this.props.customer!.verificationType) &&
							<Typography variant={"body1"} color={"darkorange"}>
								<ErrorIcon sx={{mr: 2}} />
								<Trans id="corr-divergent-adv-types">
									La metodologia di Adeguata Verifica selezionata è diversa da quella precedentemente
									richiesta per il cliente
								</Trans>
							</Typography>}
					</Grid>
					<Grid item xs={12} textAlign="right" sx={{mt: 2}}>
						<Button
							color="primary"
							variant="outlined"
							sx={{mr: 1}}
							onClick={this.backForm}>
							<Trans id={"corr-back"}> Indietro</Trans>
						</Button>
						<Button
							color="primary"
							variant="contained"
							onClick={this.submitForm}>
							<Trans id={"corr-create"}> Crea Analisi Prestazione </Trans>
						</Button>
					</Grid>
				</Grid>
			</Box>}
		</>);
	}
}