import {
	ErrorResponse,
	RestResponse,
	RestService
} from "@sinossi/mates-react-library";
import { Redirect } from "react-router-dom";
import { User } from "../pages/models";

export class SessionService
{
	static getCurrentUser = (successCallback: any, errorCallback: any) => {

		RestService.get<User>("/api/users/current")
			.then((response: RestResponse<User, ErrorResponse>) => {
				if(response.hasError()) {
					errorCallback(response);
					return;
				}

				window.sessionStorage.setItem("currentUser", JSON.stringify(response.data));
				successCallback(response.data);
			})
			.catch(console.error);

	};

	static logout()
	{
		sessionStorage.removeItem("currentUser");
		return <Redirect to="/" />;
	}
}