import {
	PersonFormProps,
	PersonFormState
} from "./models";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import {
	MatesDatePicker,
	MatesTextField,
	withUseFormHook
} from "@sinossi/mates-react-library";
import {
	Button,
	Grid,
	Typography
} from "@mui/material";
import {
	t,
	Trans
} from "@lingui/macro";
import { DocumentService } from "../../services/document.service";

class PersonForm extends React.Component <PersonFormProps & UseFormReturn, PersonFormState>
{

	constructor(props: any)
	{
		super(props);

		this.state = {
			isLoading: true
		};
	}

	componentDidMount()
	{

		this.setState(() => ({isLoading: true}));

		if(this.props.person !== null) {
			this.props.reset(this.props.person);
		}

		this.setState(() => ({isLoading: false}));
	}

	private submitForm = (values: any) => {
	};

	private downloadDocument = (id: string) => {
		DocumentService.downloadDocument(id);
	};

	private parseDocumentType = (documentKey: string) => {
		switch(documentKey) {
			case "ID_CARD":
				return <Trans id={"codf-id-card"}> Carta di Identità</Trans>;
			case "CCIA_REPORT":
				return <Trans id={"codf-ccia-report"}> Visura Camerale </Trans>;
			case "OTHER":
				return <Trans id={"codf-other"}> Altro </Trans>;
			case "BANK_ACCOUNT_STATEMENT":
				return <Trans id={"codf-bank-account-statement"}> Estratto Conto </Trans>;
			case "DRIVING_LICENCE":
				return <Trans id={"codf-driving-licence"}> Patente </Trans>;
			case "DURC":
				return <Trans id={"codf-durc"}> DURC </Trans>;
			case "OTHER_IDENTIFICATION_METHOD":
				return <Trans id={"codf-other-id"}> Altro metodo di identificazione </Trans>;
			case "LAST_BALANCE":
				return <Trans id={"codf-last-balance"}> Ultimo bilancio </Trans>;
			case "OWNER_APPOINTMENT_VERBAL":
				return <Trans id={"codf-owner-appointment-act"}> Atto di nomina degli Amministratori </Trans>;
			case "COMPANY_CONSTITUTION_ACT":
				return <Trans id={"codf-company-constitution-act"}> Atto Costitutivo </Trans>;
		}
	};

	render()
	{
		return (<>{!this.state.isLoading && <form onSubmit={this.props.handleSubmit(this.submitForm)}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<MatesTextField
						variant={"outlined"}
						name="fiscalCode"
						label={t({
							id: "pf-fiscal-code",
							message: "Codice Fiscale"
						})}
						control={this.props.control}
						trigger={this.props.trigger}
						formState={this.props.formState}
						disabled={this.props.readOnly} />
				</Grid>
				<Grid item xs={6}>
					<MatesDatePicker
						variant={"outlined"}
						name="birthDate"
						label={t({
							id: "pf-birth-date",
							message: "Data di Nascita"
						})}
						control={this.props.control}
						trigger={this.props.trigger}
						formState={this.props.formState}
						disabled={this.props.readOnly} />
				</Grid>
				<Grid item xs={6}>
					<MatesTextField
						variant={"outlined"}
						name="birthPlace"
						label={t({
							id: "pf-birth-place",
							message: "Luogo di Nascita"
						})}
						control={this.props.control}
						trigger={this.props.trigger}
						formState={this.props.formState}
						disabled={this.props.readOnly} />
				</Grid>
				<Grid item xs={6}>
					<MatesTextField
						variant={"outlined"}
						name="pec"
						label={t({
							id: "pf-pec",
							message: "PEC"
						})}
						control={this.props.control}
						trigger={this.props.trigger}
						formState={this.props.formState}
						disabled={this.props.readOnly} />
				</Grid>
				<Grid item xs={6}>
					<MatesTextField
						variant={"outlined"}
						name="email"
						label={t({
							id: "pf-email",
							message: "Indirizzo e-mail"
						})}
						control={this.props.control}
						trigger={this.props.trigger}
						formState={this.props.formState}
						disabled={this.props.readOnly} />
				</Grid>
				{/*Implement address*/}
				<Grid item xs={12}>
					<Typography sx={{fontWeight: "bold"}}>
						<Trans id="person.documents">
							Documenti
						</Trans>
					</Typography>
					<ul>
						{this.props.person?.documentList?.map((document) => (
							<li> {this.parseDocumentType(document.type)} - {Math.round(document.size / 1024)} kB <Button
								onClick={() => {
									this.downloadDocument(document.id);
								}}> Download </Button></li>))}
						{this.props.person?.documentList?.length === 0 && <> <i> <Trans id="pf-no-documents">Nessun documento presente </Trans></i></>}
					</ul>
				</Grid>
			</Grid>
		</form>}</>);
	};

}

export default withUseFormHook<{}>(PersonForm, {}, {});
