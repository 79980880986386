import React from "react";
import {
	OrderHistoryProps,
	OrderHistoryState,
	OrderListResponse
} from "./models";
import { PurcheaseableElementService } from "../../services/purcheaseable-element.service";
import {
	ErrorResponse,
	RestResponse
} from "@sinossi/mates-react-library";
import { withRouter } from "react-router-dom";
import OrderHistoryElement from "../../components/order-history/order-history-table/order-history-table";
import {
	Box,
	Grid,
	Typography
} from "@mui/material";
import { Trans } from "@lingui/macro";

class OrderHistory extends React.Component<OrderHistoryProps, OrderHistoryState>
{

	constructor(props: any)
	{
		super(props);

		this.state = {
			response: null,
			errorConfig: {
				options: {showErrorTimestamp: true},
				showErrorCode: true
			},
			openError: false,
			isLoading: true,
			orderList: null,
		};
	}

	componentDidMount()
	{
		PurcheaseableElementService.getOrders(this.getOrderListCallback);
	}

	private getOrderListCallback = (response: RestResponse<OrderListResponse, ErrorResponse>) => {

		this.setState(() => ({response: response}));

		if(!response.hasError()) {

			this.setState(() => ({
				isLoading: false,
				orderList: response.data!.list
			}));

		} else {
			this.setState(() => ({openError: true}));
		}
	};

	render()
	{
		return (<>
				<Box component="div" className="main-container">
					<Typography variant="h3">
						<Trans id="order-history.header">Storico ordini</Trans>
					</Typography>
					<Grid container spacing={2} marginTop={3}>
						<OrderHistoryElement order={this.state.orderList} />
					</Grid>
				</Box>
			</>);
	}
}

export default withRouter(OrderHistory);