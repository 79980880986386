import React from "react";
import { withRouter } from "react-router-dom";

import { AnalysisSummaryFormProps, AnalysisSummaryFormState } from "./models";

import { Button, Grid, Stack, TextField, Typography } from "@mui/material";

import { DateTime } from "luxon";
import { t, Trans } from "@lingui/macro";
import { VerificationType } from "../../../pages/models";

class AnalysisSummaryFields extends React.Component<AnalysisSummaryFormProps, AnalysisSummaryFormState> {

    private formatItalianDate = (value: string | null) => {
        if (value !== null) {
            return DateTime.fromMillis(Date.parse(value)).toFormat("dd/MM/yyyy");
        }
    };

    goToOperation = () => {
        this.props.history.push("/operation-summary/" + this.props.analysis?.id);
    };

    goToCustomer = () => {
        this.props.history.push(
            `/customer-summary/${this.props.analysis?.customer?.id}?backTo=/analysis-summary/${this.props.analysis?.id}`);
    };

    private parseAdv = (adv: VerificationType | null) => {

        if (adv === null) {
            return "/";
        }

        if (adv === "SIMPLIFIED") {
            return t({
                id: "as-adv-simplified",
                message: "Semplificata"
            });
        }

        if (adv === "NORMAL") {
            return t({
                id: "as-adv-normal",
                message: "Ordinaria"
            });
        }

        if (adv === "REINFORCED") {
            return t({
                id: "as-adv-reinforced",
                message: "Rafforzata"
            });
        }

        return "/";
    };

    getNameClient = () => {

        if (!this.props.analysis?.customer?.denomination)
            return this.props.analysis?.customer?.name + " " + this.props.analysis?.customer?.surname;

        return this.props.analysis?.customer?.denomination;
    };

    render() {
        return (<>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Stack direction={"row"}
                        spacing={2}
                        style={{
                            display: "inline-flex",
                            alignItems: "center",
                            width: "100%"
                        }}>
                        <Typography variant="body1" align="left" fontWeight={"bold"}>
                            <Trans id={"asf-start-date"}>
                                Data di inizio
                            </Trans>
                        </Typography>
                        <Typography variant="body1" align="left">
                            {this.formatItalianDate(this.props.analysis?.startDate)}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack spacing={1}
                        style={{
                            width: "100%"
                        }}>
                        <Typography variant="body1" align="left" fontWeight={"bold"}>
                            <Trans id={"asf-customer"}>
                                Cliente
                            </Trans>
                        </Typography>
                        <Stack direction={"row"} spacing={3}>
                            <TextField value={this.getNameClient()}
                                variant="outlined"
                                inputProps={{
                                    readOnly: true
                                }}
                                style={{
                                    width: "75%"
                                }}
                                size="small"></TextField>
                            <Button
                                color="primary"
                                size={"small"}
                                variant="outlined"
                                onClick={this.goToCustomer}>
                                <Trans id={"asf-more-info"}>
                                    Maggiori informazioni
                                </Trans>
                            </Button>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack
                        spacing={1}
                        style={{
                            width: "100%"
                        }}>
                        <Typography variant="body1" align="left" fontWeight={"bold"}>
                            <Trans id={"asf-operation"}>
                                Operazione
                            </Trans>
                        </Typography>
                        <Stack direction={"row"} spacing={3}>
                            <TextField value={this.props.analysis?.operation?.shortDescription}
                                variant="outlined"
                                inputProps={{
                                    readOnly: true
                                }}
                                style={{
                                    width: "75%"
                                }}
                                size="small"></TextField>
                            <Button
                                color="primary"
                                size={"small"}
                                variant="outlined"
                                onClick={this.goToOperation}>
                                <Trans id={"asf-more-info"}>
                                    Maggiori informazioni
                                </Trans>
                            </Button>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={4}>
                    <Stack direction={"row"}
                        spacing={2}
                        style={{
                            display: "inline-flex",
                            alignItems: "center",
                            width: "100%"
                        }}>
                        <Typography variant="body1" align="left" fontWeight={"bold"}>
                            <Trans id={"asf-adv-expiry"}>
                                Scadenza ADV
                            </Trans>
                        </Typography>
                        <TextField value={this.formatItalianDate(this.props.analysis?.customer?.verificationExpireDate)}
                            variant="outlined"
                            inputProps={{
                                readOnly: true
                            }}
                            size="small"
                            sx={{ width: "52%" }} />
                    </Stack>
                </Grid>
                <Grid item xs={8}>
                    <Stack direction={"row"}
                        spacing={2}
                        style={{
                            display: "inline-flex",
                            alignItems: "center",
                            width: "100%"
                        }}>
                        <Typography variant="body1" align="left" fontWeight={"bold"}>
                            <Trans id={"asf-adv-type"}>
                                Tipologia ADV
                            </Trans>
                        </Typography>
                        <TextField value={this.parseAdv(this.props.analysis!.customer!.verificationType)}
                            variant="outlined"
                            inputProps={{
                                readOnly: true
                            }}
                            size="small"
                            sx={{ width: "70%" }} />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction={"row"}
                        spacing={2}
                        style={{
                            display: "inline-flex",
                            alignItems: "center",
                            width: "100%"
                        }}>
                        {this.props.analysis?.operation?.worker && <><Typography variant="body1" align="left" fontWeight={"bold"}>
                            <Trans id={"asf-worker"}>
                                Professionista di riferimento
                            </Trans>
                        </Typography>
                            <TextField
                                value={this.props.analysis?.operation?.worker.name + " " + this.props.analysis?.operation?.worker.surname + " - " + this.props.analysis?.operation?.worker.email}
                                variant="outlined"
                                inputProps={{
                                    readOnly: true
                                }}
                                size="small"
                                sx={{ width: "58%" }} /></>}
                    </Stack>
                </Grid>
            </Grid>
        </>);
    }
}

export default withRouter(AnalysisSummaryFields);
