import {
	VerifyDataProps,
	VerifyDataState
} from "./models";
import React from "react";
import {
	Box,
	Typography
} from "@mui/material";
import { Trans } from "@lingui/macro";
import { withRouter } from "react-router-dom";
import {
	ErrorResponse,
	Loading,
	RestResponse
} from "@sinossi/mates-react-library";
import { CustomersService } from "../../services/customers.service";
import { Customer } from "../models";
import ErrorIcon from "@mui/icons-material/Error";
import CustomerRecapInformations from "../../components/customers/customer-recap-informations/customer-recap-informations";
import NavigationBack from "../../components/navigation/navigation-back/navigation-back";

class VerifyData extends React.Component<VerifyDataProps, VerifyDataState>
{

	constructor(props: any)
	{
		super(props);

		this.state = {
			isLoading: true,
			onError: false,
			customer: null
		};
	}

	private getCustomerCallback = (response: RestResponse<Customer, ErrorResponse>) => {
		if(response.hasError()) {
			this.setState(() => ({
				onError: true,
				isLoading: false
			}));
		} else {
			this.setState(() => ({
				customer: response.data,
				isLoading: false
			}));
		}
	};

	componentDidMount()
	{
		this.getCustomer();
	}

	private getCustomer = () =>
	{
		let customerId = this.props.match.params.id;
		CustomersService.getCustomer(customerId, this.getCustomerCallback);
	}

	render()
	{
		return (<Box component="div">
			<Loading show={this.state.isLoading} />
			<NavigationBack backRoute={`/customers`} />
			<Typography variant="h3" textAlign={"center"}>
				<Trans id="vd-header">Verifica Cliente</Trans>
			</Typography>
			<Box component="div"
				className="info-container"
				sx={{
					mt: 5,
					p: 4,
					backgroundColor: "#FAFBFB",
				}}>
				{!this.state.isLoading && !this.state.onError &&
					<CustomerRecapInformations
						customer={this.state.customer}
						isOnlyShow={false}
						getCustomer={this.getCustomer}/>}
				{this.state.onError &&
					<Box marginTop={4} textAlign={"center"}><ErrorIcon color="error" sx={{fontSize: 45}} />
						<Typography variant="h5" color={"error"}>
							<Trans id="verify-customer.error">
								Si è verificato un errore con l'ottenimento del
								cliente
							</Trans>
						</Typography>
					</Box>}
			</Box>
		</Box>);
	}
}

export default withRouter(VerifyData);
