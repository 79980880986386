import React from "react";
import {
	PurcheaseableElementCardProps,
	PurcheaseableElementCardState
} from "./models";
import {
	Button,
	Card,
	CardActions,
	CardContent,
	Grid,
	Typography
} from "@mui/material";
import { withRouter } from "react-router-dom";
import { PurcheaseableElementService } from "../../../services/purcheaseable-element.service";
import {
	ErrorResponse,
	RestResponse
} from "@sinossi/mates-react-library";

class PurcheasebleElementCard extends React.Component<PurcheaseableElementCardProps, PurcheaseableElementCardState>
{

	buy = () => {
		PurcheaseableElementService.buyElement(this.props.purcheaseableElement.id, this.buyCallback);
	};

	private buyCallback = (response: RestResponse<any, ErrorResponse>) => {

		if(!response.hasError()) {
			window.location.href = response.data.paymentRedirectUrl;
		} else {
		}

	};

	render()
	{
		var cardStyle = {
			variant: "outlined",
			width: "200px"
		};
		return (<Card style={cardStyle}>

			<CardContent>
				<Typography variant="h6" component="div" paddingBottom="20px">
					{this.props.purcheaseableElement.displayName}
				</Typography>
			</CardContent>
			<CardActions>
				<Grid container>
					<Grid item>
						<Typography variant="h6" component="div">
							{this.props.purcheaseableElement.amountExcludingVat}
							{" €"}
						</Typography>
					</Grid>
				</Grid>
				<Grid container justifyContent="flex-end">
					<Grid item>
						<Button variant="outlined" onClick={this.buy}>Acquista</Button>
					</Grid>
				</Grid>
			</CardActions>
		</Card>);
	}
}

export default withRouter(PurcheasebleElementCard);