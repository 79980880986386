import React from "react";
import { withRouter } from "react-router-dom";
import {
   PurchaseableElementListProps, PurchaseableElementListState
} from "./models";
import { Grid } from "@mui/material";
import PurcheasebleElementCard from "../purcheaseable-element-card/purcheaseable-element-card"


class PurcheasebleElementList extends React.Component<PurchaseableElementListProps, PurchaseableElementListState>
{



    render()
    {
        return (<>
            <Grid container spacing={2} marginTop={3}>
                {this.props.list?.map(
                    (item, index) => (<Grid key={item.id} item lg={3} xs={12} textAlign={"left"}>
                        <PurcheasebleElementCard
                            purcheaseableElement={item}
                        /> </Grid>))}
            </Grid>
        </>);
    }
}

export default withRouter(PurcheasebleElementList);