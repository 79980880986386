import React from "react";
import { withRouter } from "react-router-dom";
import {
    AnalysesListProps,
    AnalysesListState
} from "./models";
import { Grid } from "@mui/material";
import { AnalysesService } from "../../../services/analyses.service";
import AnalysisCard from "../analysis-card/analyses-card";

class AnalysesList extends React.Component<AnalysesListProps, AnalysesListState>
{

    private deleteAnalysis = (analysisId: string) => {
        AnalysesService.deleteAnalysis(analysisId, this.analysisDeleteCallback);
    };

    private analysisDeleteCallback = () => {
        this.props.refreshList();
    };

    render()
    {
        return (<>
            <Grid container spacing={2} marginTop={3}>
                {this.props.list?.map(
                    (item, index) => (<Grid key={item.id} item lg={3} xs={12} textAlign={"left"}> <AnalysisCard
                        analysis={item}
                        emitDeleteCallback={this.deleteAnalysis} /> </Grid>))}
            </Grid>
        </>);
    }
}

export default withRouter(AnalysesList);