import React from "react";

import { UseFormReturn } from "react-hook-form";

import * as Yup from "yup";

import {
	AnalysisSearchFormState,
	AnalysisSearchFormProps
} from "./models";

import { AnalysisFilter } from "../../../pages/analyses/models";

import {
	MatesDatePicker,
	withUseFormHook,
} from "@sinossi/mates-react-library";

import {
	Fab,
	Grid
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { t } from "@lingui/macro";

const AnalysisSearchFormSchema = Yup.object().shape({
	startDate: Yup.date(),
	endDate: Yup.date()
});

class AnalysisSearchForm extends React.Component<AnalysisSearchFormProps & UseFormReturn, AnalysisSearchFormState>
{

	constructor(props: any)
	{
		super(props);

		this.state = {
			loading: true
		};
	}

	componentDidMount()
	{
		if(this.props.searchFormValues !== null) {

			this.props.reset({
				startDate: this.props.searchFormValues.startDate,
				endDate: this.props.searchFormValues.endDate
			});
		}

		this.setState(() => ({loading: false}));
	}

	private submitForm = (values: any) => {

		let startDate: Date | null = null;
		let endDate: Date | null = null;

		if(values.startDate != null) {
			startDate = values.startDate;
			startDate?.setHours(0, 0, 0, 0);
		}
		if(values.endDate != null) {
			endDate = values.endDate;
			endDate?.setHours(0, 0, 0, 0);
		}

		let analysisFilter: AnalysisFilter = {
			customerId: null,
			startDate: startDate,
			endDate: values.endDate != null ? values.endDate : null,
		};

		this.props.searchFunction(analysisFilter);
	};

	private validateForm = () => {

	};

	render()
	{
		return (<>
			{!this.state.loading && <form onSubmit={this.props.handleSubmit(this.submitForm)}>
				<Grid container spacing={2}>
					<Grid item xs={5}>
						<MatesDatePicker
							name="startDate"
							variant={"outlined"}
							label={t({
								id: "asf-date-from-label",
								message: "Da"
							})}
							control={this.props.control}
							formState={this.props.formState}
							trigger={this.props.trigger}
						/>
					</Grid>
					<Grid item xs={5}>
						<MatesDatePicker
							name="endDate"
							variant={"outlined"}
							label={t({
								id: "asf-date-to-label",
								message: "A"
							})}
							showTodayButton={true}
							control={this.props.control}
							formState={this.props.formState}
							trigger={this.props.trigger}
						/>
					</Grid>
					<Grid item xs={1}>
						<Fab color="primary" aria-label="search" size={"small"} type="submit">
							<SearchIcon />
						</Fab>
					</Grid>
				</Grid>
			</form>}</>);
	}
}

export default withUseFormHook<{}>(AnalysisSearchForm, AnalysisSearchFormSchema, {});
