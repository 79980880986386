import React from "react";
import { withRouter } from "react-router-dom";

import {
	OperationSummaryProps,
	OperationSummaryState
} from "./models";

import {
	Analysis,
	Operation
} from "../models";

import { OperationService } from "../../services/operation/operation.service";

import {
	ErrorResponse,
	Loading,
	RestResponse
} from "@sinossi/mates-react-library";

import {
	Box,
	Typography
} from "@mui/material";

import {
	t,
	Trans
} from "@lingui/macro";
import { AnalysesService } from "../../services/analyses.service";
import OperationForm from "../../components/operations/operation-form/operation-form";
import NavigationBack from "../../components/navigation/navigation-back/navigation-back";

import {
	withSnackbar,
	WithSnackbarProps
} from "notistack";

class OperationSummary extends React.Component<OperationSummaryProps & WithSnackbarProps, OperationSummaryState>
{

	constructor(props: any)
	{
		super(props);

		this.state = {
			isLoading: true,
			openError: false,
			response: null,
			isClosed: false,
			errorConfig: {
				options: {showErrorTimestamp: true},
				showErrorCode: true
			},
			operation: null,
			analysis: null,
		};
	}

	componentDidMount()
	{
		AnalysesService.getAnalysis(this.props.match.params.id, this.getAnalysisCallback);
	}

	private getAnalysisCallback = (response: RestResponse<Analysis, ErrorResponse>) => {

		this.setState(() => ({response: response}));

		if(!response.hasError()) {

			this.setState(() => ({
				analysis: response.data
			}));

			if(response.data?.operation?.id !== undefined) {
				OperationService.getOperation(response.data!.operation!.id, this.getOperationCallback);
			}

		} else {
			this.setState(() => ({openError: true}));
		}
	};

	private getOperationCallback = (response: RestResponse<Operation, ErrorResponse>) => {

		this.setState(() => ({response: response}));

		if(!response.hasError()) {

			this.setState(() => ({
				operation: response.data,
				isLoading: false,
			}));

		} else {
			this.setState(() => ({openError: true}));
		}
	};

	goBack = () => {
		this.props.history.goBack();
	};

	private handleSubmit = (operation: Operation) => {
		OperationService.updateOperation(operation, this.saveOperationCallback);
	};

	private saveOperationCallback = (response: RestResponse<Operation, ErrorResponse>) => {

		this.setState(() => ({response: response}));

		if(!response.hasError()) {

			this.setState(() => ({
				operation: response.data
			}));

			this.props.enqueueSnackbar(t({
				id: "os-update-success",
				message: "Operazione aggiornata"
			}), {
				variant: "success",
			});

		} else {
			this.setState(() => ({openError: true}));

			this.props.enqueueSnackbar(t({
				id: "os-update-error",
				message: "Errore nell'aggiornamento dell'operazione"
			}), {
				variant: "error",
			});
		}
	};

	render()
	{
		return (<>
			<Loading show={this.state.isLoading} />
			{!this.state.isLoading && <>
				<NavigationBack backRoute={`/analysis-summary/${this.state.analysis!.id}`} />
				<Box component="div" className="onboard-container summary-container">
					<Typography variant="h3" marginBottom={2} textAlign={"center"} sx={{mb: 5}}>
						<Trans id="os-header">
							Riepilogo operazione
						</Trans>
					</Typography>
					<Loading show={this.state.isLoading} />
					{!this.state.isLoading && <OperationForm
						operation={this.state.operation}
						update={true}
						onOperationSubmit={this.handleSubmit} />}
				</Box>
			</>}
		</>);
	}

}

export default withSnackbar(withRouter(OperationSummary));