import React from "react";
import {
	SelectedWorkerModalProps,
	SelectedWorkerModalState,
	WorkersListResponse
} from "./models";

import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	MenuItem,
  Select,
} from "@mui/material";

import {
	Trans
} from "@lingui/macro";

import {
	ErrorResponse,
	RestResponse
} from "@sinossi/mates-react-library";

import {OperationService} from "../../../services/operation/operation.service";

export default class SelectedWorkerModal extends React.Component<SelectedWorkerModalProps, SelectedWorkerModalState>
{

	constructor(props: any)
	{
		super(props);

		this.state = {
			selectedWorkerId: null,
			officeWorkers: []
		};
	}

	componentDidMount()
  {
    this.setState(() => ({selectedWorkerId: this.props.worker.id}));
    OperationService.getOfficeWorkers(this.getWorkersCallback);
  }

  private getWorkersCallback = (response: RestResponse<WorkersListResponse, ErrorResponse>) => {
    if(!response.hasError()) {
      this.setState(() => ({
        officeWorkers: response.data?.list
      }));
    } else {
      console.log("Error get workers");
    }
  };

  private handleRevert = () => {
    this.props.closeModal(null);
  }

	private handleClose = () => {
		this.props.closeModal(this.state.selectedWorkerId);
	};

	render()
	{
		return (<>
      <DialogTitle color={"primary"}>
        <Trans id={"swm-title"}> Seleziona professionista </Trans>
      </DialogTitle>
      <DialogContent>
        <Select
          fullWidth
          onChange={(e: any) => this.setState(() => ({
          selectedWorkerId: e.target.value
        }))}>
          {this.state.officeWorkers?.map((item) => (<MenuItem value={item.id}>
            {item.name} {item.surname} - {item.email}
          </MenuItem>))}
        </Select>
        <DialogActions>
          <Button onClick={this.handleRevert}> <Trans id="swm-close"> Chiudi</Trans> </Button>
          <Button
            onClick={this.handleClose}
            variant="contained"
            color={"primary"}>
            <Trans id="swm-upload"> Salva </Trans>
          </Button>
        </DialogActions>
      </DialogContent>
		</>);
	}
}
