import React from "react";

import {
	Box,
	Typography,
} from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { Trans } from "@lingui/macro";

export class IdentificationSuccess extends React.Component<{}, {}>
{
	render()
	{
		return (<Box
			sx={{
				marginTop: 8,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
			}}
		>
			<CheckCircleIcon color="success" sx={{fontSize: 120}} />
			<Typography mt={2} mb={2} variant="h3" textAlign={"center"}>
				<Trans id="success-identification.message-title">
					Identificazione completata
				</Trans>
			</Typography>
			<Typography variant="h5" textAlign={"center"}>
				<Trans id="success-identification.message">
					Complimenti, sei stato censito correttamente.
				</Trans>
			</Typography>
			<Typography mb={5} variant="h5" textAlign={"center"}>
				<Trans id="success-identification.message-notification">
					Lo studio verificherà i dati inseriti, se necessario sarai ricontattato.
				</Trans>
			</Typography>
		</Box>);
	}
}
